/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdMenu , MdOutlineClose  } from "react-icons/md";


const Header = () => {
  const [ click , setClicked ] = useState(false)
  var AuthToken = localStorage.getItem("AuthToken")
  const handleClick = (value) =>{
    console.log("DD" , value.target.id);
    if(value?.target?.id === 'login'){
      window.location.href = '/login'
    }else if (value?.target?.id === "register"){
      window.location.href = '/sign-up'
    }
  }
  const handleLogOut = () =>{
    localStorage.clear()
    window.location.href='/'
  }
  const handleLang = (value) => {
    console.log("LANG",value?.target?.id);
    if(value?.target?.id === "ar"){
      localStorage.setItem("lang" , "ar")
      window.location.reload();
    }else{
      localStorage.setItem("lang" , "en")
      window.location.reload();
    }
  }
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';
  console.log("lang", lang);
  const Clicked = ()=>{
    setClicked(true)
  }

  const closed = ()=>{
    setClicked(false)
  }
  // useEffect(()=>{
  //   Clicked()
  // },[])

  console.log("clll",click);
  return (
    <header>
      <div className="top-header" >
        <div className="top-left-hiitfit top-left-hiitfit-desktop">
          <div className="">
            <Link id={lang==="en"?"ar":"en"} onClick={handleLang}>{lang==="ar"?"En":"ع"}</Link>
          </div>
        </div>

        <div className="middle-hiitfit">
          <img src="/images/hiitfit-new-menu.png" className="img-fluid" alt="" />
        </div>

        <div className="top-right-hiitfit top-right-hiitfit-desktop">
        <div className="login" style={{cursor:"pointer"}}>
	      {AuthToken? 
	      <Link className="register-top"  onClick={handleLogOut}>{lang==='en'?"Logout" : "تسجيل الخروج"}</Link>
        : <><Link className="login-top" id="login" style={lang !== 'en'? { fontSize:'12px'} : {cursor:"pointer"}} onClick={handleClick}>{lang==='en'? "Login" :"تسجيل الدخول"}</Link> | <Link id="register" style={lang !== 'en'? { fontSize:'12px'} : {cursor:"pointer"}} className="register-top" onClick={handleClick}>{lang==='en'? "SIGN UP" :"حساب جديد" }</Link></>
        }
	      </div>
	      {/* {AuthToken?  */}
        {/* <div className="login"  onClick={handleClick}>
          <a className="register-top" style={{cursor:"pointer"}} onClick={handleLogOut}>Logout</a>
          <Link to="/login" className="login-top" id="login">Login</Link> 
          <Link to="/sign-up" id="register" className="register-top" onClick={handleClick}>SIGN UP</Link>
	      </div>	 */}
        {/* } */}
        </div>

      </div>
      
      <nav className="navbar navbar-expand-md desktop-navbar">
        <div className="container-fluid" >
          <a className="navbar-brand d-md-none" href="/">
            <img
              src="https://codingyaar.com/wp-content/uploads/logo-1.png"
              alt=""
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav" >
            <ul className="navbar-nav navbar-nav-menu mx-auto">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  href="/"
                >
                 {lang==='en' ? "Home" : "الصفحة الرئيسة"}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/workout-program" >
                 {lang==='en'? "Workout Programs" :"برامج التدريبات"}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/nutirtion-center">
                  {lang==='en'? "Nutrition Center":"مركز التغذية"}
                </a>
              </li>
              <a className="navbar-brand d-none d-md-block" href="/">
                <img src="/images/hiitfit-new-menu.png" alt="" />
              </a>
              <li className="nav-item">
                <a className="nav-link" href="/live-session">
                  {lang==='en'? "Live Session" :"تدريبات البث المباشر"}
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/blog">
                 {lang==='en'? "Blog" : "المدونة"}
                </a>
              </li>
             {AuthToken ? 
              <li className="nav-item">
                  <a className="nav-link" href="/my-plan">
                    {lang==="en"? "My Plan": "نظام تدريبي"}
                  </a>
              </li>
             : "" }

             { AuthToken ? 
              <li className="nav-item">
                <a className="nav-link" href="/my-account">
                 {lang==="en"? "My Account" :"حسابي"}
                </a>
              </li>
               : ""}
            </ul>
          </div>
        </div>
      </nav>

      <div className="navigation mobile-navigation">
        <div className={!click?"wsmenucontainer clearfix":"wsmenucontainer clearfix wsoffcanvasopener"}>
          <div className="overlapblackbg"></div>
          {/* <div className={click? "wsmobileheader clearfix wsoffcanvasopener" : "wsmobileheader clearfix"}>
            {click?
              <MdOutlineClose id="wsnavtoggle" className="animated-arrow " onClick={closed } />
            :
            <MdMenu id="wsnavtoggle" className="animated-arrow " onClick={Clicked} />  }
          </div> */}
          { click ?
          <div className="wsmobileheader clearfix wsoffcanvasopener">
              {/* <MdOutlineClose id="wsnavtoggle" className="animated-arrow " style={{color:'red'}} onClick={closed } /> */}
             <span className="clsIcon" style={{ zIndex:'999'}} onClick={closed }> <i className="fa fa-solid fa-times"></i></span>
             
          </div>
          :
          <div className="wsmobileheader clearfix ">
             <span id="wsnavtoggle" className="animated-arrow" style={{color:'rgba(92, 89, 89, 0.712)'}}  onClick={Clicked }> <i className="fa fa-solid fa-bars"></i></span>
            {/* <MdMenu id="wsnavtoggle" className="animated-arrow " onClick={Clicked} />   */}
          </div>
          }
      
          <div className="wsmain">
            <img
              src="/images/hiitfit-logo.svg"
              className="img-fluid img-mobile"
              alt="HIITFIT"
            />
            <nav className="wsmenu clearfix">
              <ul id="nav" className="mobile-sub wsmenu-list">
                <li>
                  <a href="/" className="active">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/workout-program">Workout Programs</a>
                </li>
                <li>
                  <a href="/nutirtion-center">Nutrition Center </a>
                </li>
                <li>
                  <a href="/live-session">Live Session</a>
                </li>
                <li>
                  <a href="/your-plan">Your Plan</a>
                </li>
                <li>
                  <a href="/blog">Blog</a>
                </li>
                <li>
                  <a href="/contact">Contact</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      
    </header>
  );
};

export default Header;
