import React, { useState, useRef, useEffect } from 'react';
import { Player } from 'video-react';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import "video-react/dist/video-react.css";


const Testing = () => {
  const [playerSource, setPlayerSource] = useState('https://media.w3.org/2010/05/sintel/trailer_hd.mp4');
  const [inputVideoUrl, setInputVideoUrl] = useState('http://www.w3schools.com/html/mov_bbb.mp4');
  const playerRef = useRef(null);

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.load();
    }
  }, [playerSource]);

  const handleValueChange = (e) => {
    const { value } = e.target;
    setInputVideoUrl(value);
  };

  const updatePlayerInfo = () => {
    setPlayerSource(inputVideoUrl);
  };

  return (
    <div>

      <Player
        ref={playerRef}
        videoId="video-1"
        width={'640px'}
        height={'360px'}
        autoPlay
      >
        <source src={playerSource} />
      </Player>
      <div className="docs-example">
        <Form>
          <FormGroup>
            <Label for="inputVideoUrl">Video Url</Label>
            <Input
              name="inputVideoUrl"
              id="inputVideoUrl"
              value={inputVideoUrl}
              onChange={handleValueChange}
            />
          </FormGroup>
          <FormGroup>
            <Button type="button" onClick={updatePlayerInfo}>
              Update
            </Button>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
};

export default Testing;
