import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubsHistory } from "../redux/MainAction";
import moment from 'moment'

const SubscriptionHistory = () => {
  const dispatch = useDispatch()
  const History = useSelector(state=>state?.HitFitSite?.subscriptionHistory)

  useEffect(()=> {
    getList()
  },[]) 

  const getList = () => {
      var data = {
        // includePackages:true,
      }
      dispatch(getSubsHistory(data))
  }
  console.log("HH", History);
  var date = new Date(History[0]?.joinedDate)
  var month = date.getMonth()
  var getFullYear = date.getFullYear()
  var day = date.getDate()
  console.log("date" ,day , month , getFullYear );
  return (
    <>
      <section className="inner-banner-main">
        <img src="/images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>
      
      <section class="innerpage-section">
        <div class="padding-left-right">
          <div class="container">
            <div class="diet-head">
              <h2>Your Subscription History</h2>
            </div>

            <div class="row g-2">
              { History?.map((his)=>(
                <div class="col-lg-12 col-md-12">
                  <div class=" subscription-col">
                    <div class="date-method">
                      <h5>
                        <i class="far fa-calendar"></i> {moment(his?.joinedDate).format("L")}
                      </h5>
                      <p>
                        <i class="far fa-money-bill-alt"></i> Payment Method -
                        {his?.platform}
                      </p>
                    </div>
                    <div class="total-subscription">
                      <h5>{his?.productName} Subscription</h5>
                      <p>Total : ${his?.price}</p>
                    </div>
                  </div>
                </div>
              ))
              }
              {/* <div class="col-lg-12 col-md-12">
                <div class=" subscription-col">
                  <div class="date-method">
                    <h5>
                      <i class="far fa-calendar"></i> 02/20/2023
                    </h5>
                    <p>
                      <i class="far fa-money-bill-alt"></i> Payment Method -
                      Apple
                    </p>
                  </div>
                  <div class="total-subscription">
                    <h5>Monthly Subscription</h5>
                    <p>Total : $9.99</p>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-lg-12 col-md-12">
                <div class=" subscription-col">
                  <div class="date-method">
                    <h5>
                      <i class="far fa-calendar"></i> 02/20/2023
                    </h5>
                    <p>
                      <i class="far fa-money-bill-alt"></i> Payment Method -
                      Apple
                    </p>
                  </div>
                  <div class="total-subscription">
                    <h5>Monthly Subscription</h5>
                    <p>Total : $9.99</p>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-lg-12 col-md-12">
                <div class=" subscription-col">
                  <div class="date-method">
                    <h5>
                      <i class="far fa-calendar"></i> 02/20/2023
                    </h5>
                    <p>
                      <i class="far fa-money-bill-alt"></i> Payment Method -
                      Apple
                    </p>
                  </div>
                  <div class="total-subscription">
                    <h5>Monthly Subscription</h5>
                    <p>Total : $9.99</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionHistory;
