/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {onLogin , forgetPassword , veryfyOtp} from "../../redux/MainAction"
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch()

  const [ loginData , setLoginData ] = useState({
    email:"",
    password:""
  })

  const [showPassword, setShowPassword] = useState(false)

  const [ open , setOpen ] = useState(false)
  const [ normal , setNormal ] = useState(false)
  const [ google , setGoogle ] =useState({
    email:''
  })

  const googleLogin = (e) =>{
    const {value , name} = e.target
    setGoogle({...google,[name]:value})
  }

  console.log("NORMAL", normal);

  const [ forgot , setForgot ] = useState({
    email:"",
    otp:"",
    newPassword:''
  })
  console.log("OPEN", open);
  const onPassForgot = (e) =>{
    const {value , name} = e.target
    setForgot({...forgot,[name]:value})
  }

  const onHandleChange = (e) =>{
    const {value , name} = e.target
    setLoginData({...loginData,[name]:value})
  }

  const onSubmit = (e) => {
    e.preventDefault()
    var data = {
      email: loginData?.email,
      password: loginData?.password,
      loggedInWith:"normal",
    }
    dispatch(onLogin(data))
  }
  const changeForgot = (e) => {
    console.log("EEE", e.target.id);
    if(e.target.id === "rstEmail"){
      var data = {
        email: forgot.email
      }
      dispatch(forgetPassword(data))
      setOpen(true)
    }
    else if(e.target.id === "chngePass"){
      var data = {
        email:forgot.email,
        otp: parseInt(forgot.otp),
        newPassword:forgot.newPassword
      }
      dispatch(veryfyOtp(data))
      setOpen(false)
      setForgot({
        email:"",
        otp:"",
        newPassword:''
      })

    }
  }

  const onSubmitGoogle =(e)=>{
    e.preventDefault()
    var data = {
      email: google?.email,
      loggedInWith:"google",
    }
    console.log("GOOGLE", data);
    dispatch(onLogin(data))
  }

  const handleGoogle = (e)=>{
    // console.log("GOOGLE" ,  e.target.id);
    setNormal(true)
  }
  return (
    <>
      <section className="innerpage-section login-section" >
        <div className="padding-left">
          <div className="container">
             { normal=== false ? 
            <div className="login-content">
            <h2>Welcome Back!</h2>
              <h3>Please Login to continue your workout</h3>
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="log-form">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email..."
                        name="email"
                        value={loginData?.email}
                        onChange={onHandleChange}
                      />
                    </div>
                    <div className="form-group showpass">
                      <input
                        type={showPassword?"text":"password"}
                        className="form-control"
                        placeholder="Password..."
                        name="password"
                        value={loginData?.password}
                        onChange={onHandleChange}
                      />
                      <Link
                        // to="/"
                        onClick={()=>setShowPassword(!showPassword)}
                        className={showPassword?"show-password active":"show-password"}
                        title="Show Password"
                      ></Link>
                    </div>
                  </div>
                  <div className="form-group log-form">
                    <button type="submit" className="button"  onClick={onSubmit} >
                      Sign In
                    </button>
                    <p className="forgot-reg">
                      <Link
                        className="forgot-link hover-target"
                        data-bs-toggle="collapse"
                        to="#collapseExample"
                        role="button"
                        aria-expanded="false"
                        aria-controls="collapseExample"
                      >
                        Forgot Password
                      </Link>
                    </p>
                  </div>

                  <div className="collapse log-form" id="collapseExample">
                    <div className="form-group ">
                      <input
                        type="email"
                        className="form-control mt-2"
                        placeholder="Email..."
                        value={forgot.email}
                        name="email"
                        onChange={onPassForgot}
                      />
                      { open? 
                      <div className="col-lg-12">
                      <input
                        type="number"
                        className="form-control mt-2"
                        placeholder="OTP"
                        value={forgot.otp}
                        name="otp"
                        onChange={onPassForgot}
                      />
                      <input
                        type="password"
                        className="form-control mt-2"
                        placeholder="New Password"
                        value={forgot.newPassword}
                        name="newPassword"
                        onChange={onPassForgot}
                      />
                      </div>
                      :""
                      }
                      <button
                        type="submit"
                        className="button hover-target"
                        id={open!==true?"rstEmail":"chngePass"}
                        style={{marginTop:"1rem"}}
                        onClick={changeForgot}
                      >
                        Reset Password
                      </button>
                    </div>
                  </div>

                  <div className="">
                    Are you new to HiitFit ?{" "}
                    <a href="/sign-up">Register</a>
                  </div>
                  <br />
                  <span className="or-login">OR</span>
                  <div className="login-with" id="google" style={{cursor:"pointer"}}  onClick={handleGoogle}>
                    Sign In With
                    <a className="login-link" >
                      <img
                        src="images/google-plus.jpg"
                        className="img-fluid"
                        alt=""
                        id="google"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
              :
             
              <div className="login-content" style={{marginBottom:"12rem"}}>
              <h2>Welcome Back!</h2>
              <h3>Please Login to continue your workout</h3>
              <div className="row g-0">
                <div className="col-lg-12">
                  <div className="log-form">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email..."
                        name="email"
                        value={google?.email}
                        onChange={googleLogin}
                      />
                    </div>
                  </div>
                  <button type="submit" className="button"  onClick={onSubmitGoogle} >
                      Sign In
                    </button>
                </div>
              </div>
              </div>        
             }
             
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
