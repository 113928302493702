import React, { useEffect } from "react";
import { getLiveSession  } from "../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";

const LiveSession = () => {
  var log = localStorage.getItem("isloggedIn")
  const dispatch = useDispatch()
  const live = useSelector(state=>state?.HitFitSite?.liveSession)

  useEffect(()=> {
    getList()
  },[]) 
  const getList = () => {
    if(log){
      var data ={
        // includePackages:true,
      }
      dispatch(getLiveSession(data))
    }
  }
  console.log("LIVE", live);
  return (
    <>
      <section className="innerpage-section live-session">
        <div className="padding-left-right">
          <div className="container">
            <h2>Live Session</h2>
            <h3>Today's Live Session</h3>
            <div className="row g-2">
              { live?.length>0 ? live?.map((live)=>(
                <div className="col-lg-6 col-md-12">
                {console.log("P",)}
                <div className="session-col">
                  <div className="row">
                    <div className="col-lg-5 col-md-4 col-sm-12">
                      <div className="workout-listings">
                        <img
                          src={live?.Media?.url}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12">
                      <div className="workout-listings-content">
                        <h3>{live?.workoutName}</h3>
                        <p>
                          {live?.description}
                        </p>
                        <div className="notify">
                          <div className="date-notify">
                            <h5>08:00PM, Tomorrow</h5>
                            <span className="live-now">
                              <a href="/join-live-session">{live?.status==="live"? live?.status + " now":live?.status }</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              ))
              : 
              <h2>Coming soon...</h2>
              }
              
              {/* <div className="col-lg-6 col-md-12">
                <div className="session-col">
                  <div className="row">
                    <div className="col-lg-5 col-md-4 col-sm-12">
                      <div className="workout-listings">
                        <img
                          src="images/session/session-3.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12">
                      <div className="workout-listings-content">
                        <h3>Circuit</h3>
                        <p>
                          Generally, this particular diet is followed for
                          ethical or environmental reasons.{" "}
                        </p>
                        <div className="notify">
                          <div className="date-notify">
                            <h5>08:00PM, Tomorrow</h5>
                            <span>
                              <a href="javascript:void(0)">Notify Me</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-md-12">
                <div className="session-col">
                  <div className="row">
                    <div className="col-lg-5 col-md-4 col-sm-12">
                      <div className="workout-listings">
                        <img
                          src="images/session/session-4.jpg"
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12">
                      <div className="workout-listings-content">
                        <h3>Exercises</h3>
                        <p>
                          Generally, this particular diet is followed for
                          ethical or environmental reasons.{" "}
                        </p>
                        <div className="notify">
                          <div className="date-notify">
                            <h5>08:00PM, Tomorrow</h5>
                            <span className="live-now">
                              <a href="/join-live-session">Go Live</a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LiveSession;
