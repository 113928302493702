import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onPackageProgram , onPackage} from "../redux/MainAction";

const WorkoutListing = () => {
  const params = useParams()
  const dispatch = useDispatch()

  const PackageList = useSelector(state=>state?.HitFitSite?.package)
  const ProgramList = useSelector(state=>state?.HitFitSite?.program)
  // // window.location.reload()
  console.log("Param",PackageList);
  const [ PACKAGE , setPACKAGE ] = useState([])

  useEffect(()=> {
    getList()
  },[]) 

  const getList = () => {
    if(log){
      dispatch(onPackage())
    }
  }

  var log = localStorage.getItem("isloggedIn")
  useEffect(()=> {
    if(params?.pack_id){
      var data = {
        includePackages:true,
        includeWorkouts:true,
        packageId:parseInt(params?.pack_id)
      }
      dispatch(onPackageProgram(data))
    }
  },[params.pack_id])

  useEffect(()=>{
    if(PackageList && params?.pack_id){
      var spr = PackageList?.filter(data=>data?.id==params.pack_id)
      console.log(" Spr", spr);
      setPACKAGE(spr[0])
    }
  },[ PackageList , params.pack_id])

  return (
    <>
      <section className="inner-banner-main">
        <img src="/images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>

      <section className="innerpage-section">
        <div className="padding-left-right">
          <h2>Workouts Programs</h2>
          <div className="container">
            <div className="workout-div">
              <h3>{PACKAGE?.title}</h3>
              {/* <!--<h6><a href="javascript:void(0)">See All</a></h6>-->	 */}
            </div>
            <div className="row g-4 ">
              { ProgramList?.map((prog)=>(
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="workout-col">
                <a  href={"/workouts-plan/" + prog.id}>
                    <img
                      src={prog?.MainImage?.url}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="workout-left">
                      <h4>{prog?.title}</h4>
                      <span className="days">{prog?.totalDuration}</span>
                    </div>
                    <div className="workout-right">
                      <h4>{prog?.level}</h4>
                      <span className="days">{prog?.perDayDuration}</span>
                    </div>
                </a>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WorkoutListing;
