import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getYourPlan} from "../redux/MainAction";
import { useParams } from "react-router-dom";

const YourPlan = () => {
  var log = localStorage.getItem("isloggedIn")
  const param = useParams()
  const dispatch = useDispatch()
  const PlanList = useSelector(state=>state?.HitFitSite?.yourPlan)


  
  console.log("PARAM" , param?.prog_id);
  // var ID = param?.prog_id

  useEffect(()=> {
    getList()
  },[]) 
  
  const getList = () => {
    if(log){
      // var data ={
      //   includePackages:true,
      // }
      dispatch(getYourPlan())
    }
  }

   

  // console.log("DATA" , ProgramList);
  
  return (
    <>
     <section className="inner-banner-main">
        <img src="/images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>

      <section className="innerpage-section">
        {/* <div className="padding-left-right">
          <div className="container">
            <h2>Workout Programs</h2>
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-12 col-md-12">
                <div className="hittfitt-video">
                  <video
                    src={url}
                    className="workout-video"
                    autoPlay ="true"
                    id="myVideo"
                    controls
                    muted=""
                    loop=""
                    playsInline
                  >
                    <source  type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-7">
                <h3>{videoData ? videoData?.titleEn : "Quick HIIT Workouts"}</h3>
                <p>
                 { videoData? videoData?.descriptionEn : "This workout was designed to help you to perform HIIT by using simple Basic Moves such as push-ups, Jumps & squatsIt is a 30 days program that will change your life."}
                </p>
                <div className="workout-horizontal-listings d-flex justify-content-center text-center">
                  <ul>
                    <li>
                      <img
                        src="/images/workouts/timer.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      12.5 Hours
                    </li>
                    <li>
                      <img
                        src="/images/workouts/flag.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      12 Videos
                    </li>
                    <li>
                      <img
                        src="/images/workouts/cal.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      304 KCal
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="workout-details-col">
              <h3>
                Download Full Workout{" "}
                <img
                  src="/images/workouts/download.svg"
                  className="img-fluid download-icon"
                  alt=""
                />
              </h3>
              <div className="row g-2">
                {ProgramList?.map((item, idx)=>(

                  <div className="col-lg-6">
                  <div className="workout-col-1" key={idx}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="workout-listings">
                          <img
                            src={item?.MainImage?.url}
                            className="img-fluid "
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="workout-listings-content">
                          <h4>{item?.titleEn}</h4>
                          <p>
                            {item?.titleEn}
                            <a href="#video1" data-fancybox="">
                             <button 
                             className=" workout-details-play-icon"
                             onClick={()=>handleURL(item)}
                             >
                               <img
                                src="/images/workouts/play-icon.svg"
                                alt=""
                                className="img-fluid"
                              />
                             </button>
                            </a>
                          </p>
                          <ul className="workouts-details-listings-desc">
                            <li>
                              <img
                                src="/images/icons/time-icon.png"
                                className="img-fluid"
                                alt=""
                              />{" "}
                              {item?.perDayDurationEn}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))
                }
              </div>
            </div>
          </div>
        </div> */}
        <div className="padding-left-right">
          <h2>My Plan List</h2>
          <div className="container">
            <div className="workout-div">
              {/* <h3>title</h3> */}
              {/* <!--<h6><a href="javascript:void(0)">See All</a></h6>-->	 */}
            </div>
            <div className="row g-4 ">
              { PlanList?.map((prog)=>(
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="workout-col">
                <a  href={"/my-plan/" + prog.id}>
                    <img
                      src={prog?.MainImage?.url}
                      className="img-fluid"
                      alt=""
                    />
                    <div className="workout-left">
                      <h4>{prog?.title}</h4>
                      <span className="days">{prog?.totalDuration}</span>
                    </div>
                    <div className="workout-right">
                      <h4>{prog?.level}</h4>
                      <span className="days">{prog?.perDayDuration}</span>
                    </div>
                </a>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default YourPlan;
