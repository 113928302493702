/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { getSubsCription , AddSubscription} from '../redux/MainAction'
import { useDispatch, useSelector } from 'react-redux'

const SubscriptionWithUs = () => {
  const dispatch = useDispatch()
  const SubsCiptionList = useSelector(state=> state?.HitFitSite?.subscrptionList)

  useEffect(()=>{
    Subscription()
  },[])

  const Subscription = () => {
    var data = {
      "platform":"website",
      isActive:true
    }
    dispatch(getSubsCription(data))
  }
  const handleID = (id)=>{
    var data ={
      subscriptionId: id,
      currency:"USD"
    }
    dispatch(AddSubscription(data))
    console.log ( "List", data);

  }

  return (
    <>
      <section class="innerpage-section">
        <div class="padding-left-right">
          <div class="container">
            <div class="diet-head">
              <h2>Subscribe With Us</h2>
            </div>

            <div class="row d-flex justify-content-center text-center g-2">
              { SubsCiptionList?.map((item)=>(
                <div class="col-lg-4 col-md-6">
                  <div class="subscription-plan">
                    <h1>{item?.title}</h1>
                    <div class="pricing__price">
                      <span class="pricing__currency">$</span>{item?.price}
                      <span class="pricing__period"> /mo</span>
                    </div>
                    <div class="billed">
                      <h4>Billed Every {item.title}</h4>
                      <a className="hitSubs" onClick={()=>handleID(item?.id)}>Hiit It Now!!</a>
                    </div>
                  </div>
                </div>
              ))
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SubscriptionWithUs;
