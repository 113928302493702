import React, { useRef, useEffect } from 'react';

const HomeVideo = () => {
    const videoRef = useRef(null);
    useEffect(() => {
        const video = videoRef.current;
        if (video) {
          const playPromise = video.play();
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              // Autoplay was prevented.
              // You can handle this case, e.g., by showing a play button.
              console.log("Autoplay was prevented: ", error);
            });
          }
        }
      }, []);
  return (
    <section className="">
        <div className="jumbotron">
          {/* <video className='' style={{width:'100%'}} autoplay="true" muted='true' playsinline  loop="true" >
            <source src="/video.mp4" type="video/mp4"  />
          </video> */}
            <video
                ref={videoRef}
                className=''
                style={{width:'100%'}}
                autoPlay
                muted
                playsInline
                loop
                >
                <source src="/cover_video_final3.mp4" type="video/mp4" />
            </video>
        </div>
      </section>
  )
}

export default HomeVideo