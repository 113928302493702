import axios from "axios";
import Constant from "../Constant";
import { GET_ADS, GET_BLOG, GET_CATEGORY, GET_HOME_PAGE, GET_LIVE_SESSION, GET_NUTRITION_DIET_PLAN, GET_NUTRITION_MEAL, GET_PACKAGE, GET_PRIVACY_POLICY, GET_PROGRAM, GET_REFUNDT_POLICY, GET_SUBSCRIPTION, GET_SUBSCRIPTION_HISTORY, GET_TERMS_CONDITION, GET_USER_DETAILS, GET_WEB_IMAGE, GET_YOUR_PLAN, JOIN_PROGRAM, LOGIN_DETAILS, SET_NEW_PASSWARD, SIGN_UP_DETAILS } from "./types";
import swal from "sweetalert";


var token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OTA3LCJpYXQiOjE2ODY1NjQ3OTAxNjB9.VIvjYc_ygbUPj7QqhOc8Yr7sJ9UtRcc2blQC7d8lx-A"
var lang = localStorage.getItem("lang")===null ? "en" : localStorage.getItem("lang")
const config = {
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken") || token }` ,
        "Accept-Language": `${lang}`
    }
};

console.log("lang" ,lang);
// export const onSignUpUser = (data) => (dispatch) => {
//     axios
//     .post(Constant.getAPI() + `/user/signup`, data)
//     .then((res) => {
//         if(res?.data?.success){
//           console.log( "SIGNUP DATA" , res?.data?.data);
//             dispatch({
//                 type: SIGN_UP_DETAILS,
//                 payload: res?.data?.data,
//             });
//               // window.location.href="/login"
//         }else {
//             dispatch({
//               type: SIGN_UP_DETAILS ,
//               payload: {},
//             });
//           }
//     })
//     .catch((err) => {
//         console.log(err.response.data.message);
//         dispatch({
//           type: SIGN_UP_DETAILS,
//           payload: {},
//         });
//         // swal({
//         //   title: err.response.data.message,
//         //   text: "email or password is wrong!",
//         //   icon: "error",
//         //   button: "Cancel",
//         // });
//       });
// }

// USER LOGIN 
export const onLogin = (data) => (dispatch) => {
    axios
    .post(Constant.getAPI() + `/user/signin`, data)
    .then((res) => {
        if(res?.data?.success){
          console.log("GGG" , res.data.data);
            dispatch({
                type: LOGIN_DETAILS,
                payload: res?.data?.data,
              });
    
              localStorage.setItem("isloggedIn", true);
              localStorage.setItem("email", res.data.data.email);
              localStorage.setItem("fullName", res.data.data.fullName);
              localStorage.setItem("phone", res.data.data.phone);
              localStorage.setItem("userName", res.data.data.userName);
              localStorage.setItem("AuthToken", res.data.data.token);
              localStorage.setItem("roleId", res.data.data.roleId);
              localStorage.setItem("web", true);
              localStorage.setItem("lang","en");
    
              window.location.href="/"
        }else {
          swal({
            title: "Error",
            text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
        }
    })
    .catch((err) => {
        console.log(err.response.data.message);
        dispatch({
          type: LOGIN_DETAILS,
          payload: {},
        });
        swal({
          title: err.response.data.message,
          text: "email or password is wrong!",
          icon: "error",
          button: "Cancel",
        });
      });
}

// // LOGIN WITH GOOGLE
// export const onLoginGoogle = (data) => (dispatch) => {
//   axios
//   .post(Constant.getAPI() + `/user/signin`, data)
//   .then((res) => {
//       if(res?.data?.success){
        
//           dispatch({
//               type: LOGIN_DETAILS,
//               payload: res?.data?.data,
//             });
  
//             localStorage.setItem("isloggedIn", true);
//             localStorage.setItem("email", res.data.data.email);
//             localStorage.setItem("fullName", res.data.data.fullName);
//             localStorage.setItem("phone", res.data.data.phone);
//             localStorage.setItem("userName", res.data.data.userName);
//             localStorage.setItem("AuthToken", res.data.data.token);
//             localStorage.setItem("roleId", res.data.data.roleId);
//             localStorage.setItem("lang","en");
  
//             // window.location.href="/"
//       }else {
//         swal({
//           title: "Error",
//           text: "email or password is wrong!",
//           icon: "error",
//           button: "Cancel",
//         });
//       }
//   })
//   .catch((err) => {
//       console.log(err.response.data.message);
//       dispatch({
//         type: LOGIN_DETAILS,
//         payload: {},
//       });
//       swal({
//         title: err.response.data.message,
//         text: "email or password is wrong!",
//         icon: "error",
//         button: "Cancel",
//       });
//     });
// }


// GET HOME PAGE DATA
export const onHomePage = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/user/homeV2`,data, config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_HOME_PAGE,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_HOME_PAGE ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      // console.log(err.r);
      dispatch({
        type: GET_HOME_PAGE,
        payload: {},
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// GET PACKAGES
export const onPackage = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/package/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language":`${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_PACKAGE,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_PACKAGE ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_PACKAGE,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
});
}

// GET PROGRAM 
export const onPackageProgram = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/package/program/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {  
      if(res?.data?.success){
          dispatch({
              type: GET_PROGRAM,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_PROGRAM ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_PROGRAM,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// GET NUTRITION DIET PLAN
export const getNutrtionDietPlan = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/nutrition/dietplan/list`, data, config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_NUTRITION_DIET_PLAN,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_NUTRITION_DIET_PLAN ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_NUTRITION_DIET_PLAN,
        payload: {},
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// GET MEal
export const getNutritionMeal = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI()+`/nutrition/meal/list` , data , config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type:GET_NUTRITION_MEAL,
              payload:res?.data?.data
          })
      }else{
          dispatch({
              type:GET_NUTRITION_MEAL,
              payload:[]
          })
      }
      console.log("data" , res.data);
  })
  .catch((err) => {
      dispatch({
          type:GET_NUTRITION_MEAL,
          payload:[]
      })
  });

}

// GET LIVE SESSION
export const getLiveSession = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/live-session/list`, data, config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_LIVE_SESSION,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_LIVE_SESSION ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_LIVE_SESSION,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// GET YOUR PLAN
export const getYourPlan = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/user/plan`, data, config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_YOUR_PLAN,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_YOUR_PLAN ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_YOUR_PLAN,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// JOIN PROGRAM
// GET YOUR PLAN
export const joinProgram = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/package/program/join`, data, config)
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: JOIN_PROGRAM,
              payload: res?.data?.data,
            });
          window.location.href=("/my-plan")
      }else {
          dispatch({
            type: JOIN_PROGRAM ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data);
      if(err.response.data.error === "You have not joined any subscription"){
        window.location.href="/subscribe-with-us" 
      }
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
    });
}

// GET USER DETAILS

export const getUserDetial = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/user/details`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_USER_DETAILS,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_USER_DETAILS ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_USER_DETAILS,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
});
}

// GET SUBSCIPTION HISRTORY
export const getSubsHistory = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/subscription/join/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_SUBSCRIPTION_HISTORY,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_SUBSCRIPTION_HISTORY ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_SUBSCRIPTION_HISTORY,
        payload: [],
      });
      // swal({
      //   title: err.response.data.message,
      //   text: "email or password is wrong!",
      //   icon: "error",
      //   button: "Cancel",
      // });
});
}

// SET NEW PASSWORD
export const setNewPassword = (data) => (dispatch) => {
  axios
  .put(Constant.getAPI() + `/user/change-password`, data, config)
  .then((res) => {
      if(res?.data?.success){
        dispatch(getUserDetial())
        swal({
            title: 'Successfully',
            text: `Your Password Change Successfully`,
            icon: "success",
            button: "ok",
          });
          window.location.href='/'
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      swal({
        title: err.response.data.message,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
    });
}

// CONTACT US ENQUIRY
export const contactEnquiry = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/enquiry/add`, data, config)
  .then((res) => {
      if(res?.data?.success){
        swal({
            title: 'Successfully',
            text: `Your Message Successfully Send`,
            icon: "success",
            button: "ok",
          });
          window.location.href='/'
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      swal({
        title: err.response.data.message,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
    });
}

// EDIT PROFILE
export const editProfile = (data) => (dispatch) => {
  axios
  .put(Constant.getAPI() + `/user/edit-profile`, data, config)
  .then((res) => {
      if(res?.data?.success){
        dispatch(getUserDetial())
        swal({
            title: 'Successfully',
            text: `Your Profile Change Successfully`,
            icon: "success",
            button: "ok",
          });
          window.location.href='/'
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      swal({
        title: "Error",
        text: "Please Fill All Data Correctly!",
        icon: "error",
        button: "Cancel",
      });
    });
}

// GET TERMS & CONDITION
export const getTERMS = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/terms-conditions/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_TERMS_CONDITION,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_TERMS_CONDITION ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_TERMS_CONDITION,
        payload: [],
      });
      swal({
        title: err.response.data.message,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
});
}

// REFUND POLICY
export const getREFUNDPOLICY = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/refund-policy/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_REFUNDT_POLICY,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_REFUNDT_POLICY ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_REFUNDT_POLICY,
        payload: [],
      });
      swal({
        title: err.response.data.message,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
});
}

// GET PRIVACY POLICY
export const getPRIVACYPOLICY = (data) => (dispatch) => {
  axios
  .get(Constant.getAPI() + `/privacy-policy/list`, {
    params: data,
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
        "Accept-Language": `${lang}`
    }
  } )
  .then((res) => {
      if(res?.data?.success){
          dispatch({
              type: GET_PRIVACY_POLICY,
              payload: res?.data?.data,
            });
      }else {
          dispatch({
            type: GET_PRIVACY_POLICY ,
            payload: [],
          });
        }
  })
  .catch((err) => {
      console.log(err.response.data.message);
      dispatch({
        type: GET_PRIVACY_POLICY,
        payload: [],
      });
      swal({
        title: err.response.data.message,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
});
}

// FORGOT PASSWARD
export const forgetPassword = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/user/forgot-password`, data, config)
  .then((res) => {
      if(res?.data?.success){
        swal({
            title: res.data.otp,
            text: res.data?.message,
            // text: res?.data?.otp ,
            icon: "success",
            button: "ok",
          });
        }
        localStorage.setItem('PassCh', true)
  })
  .catch((err) => {
      console.log(err.response.data.message);
      swal({
        title: err.response.data.error,
        text: "email or password is wrong!",
        icon: "error",
        button: "Cancel",
      });
    });
}

// VERIFY OTP
export const veryfyOtp = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/user/forgot-password/verify-otp`, data, config)
  .then((res) => {
      if(res?.data?.success){
        swal({
            title: 'Successfully',
            text: res?.data?.message,
            icon: "success",
            button: "ok",
          });
        }
        localStorage.setItem('PassCh', true)
  })
  .catch((err) => {
      console.log(err.response.data.message);
      swal({
        title: "Error",
        text: err.response.data.message ,
        icon: "error",
        button: "Cancel",
      });
    });
}

export const getSubsCription = (data) => (dispatch) => {
    axios
    .get(Constant.getAPI() + `/subscription/list` , {
      params:data,
      headers: {
          "Content-Type": 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("AuthToken")}`,
          "Accept-Language": `${lang}`
      }
  })
    .then(res=> {
        if(res.data){
            dispatch({
                type: GET_SUBSCRIPTION,
                payload: res.data.data
            })
        }else{
            dispatch({
                type: GET_SUBSCRIPTION,
                payload: res.data.data
            })
        }
    })
    .catch((err) => {
        swal({
            title: err.response.data,
            //  text: "email or password is wrong!",
            icon: "error",
            button: "Cancel",
          });
    });
}

export const AddSubscription = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/subscription/join/web`, data, config)
  .then((res) => {
      if(res?.data?.success){
        swal({
            title: 'Loading...',
            text: "please wait few minutes..",
            icon: "success",
            button: "ok",
          });
          // console.log("URL", ;
          window.location.href= res?.data?.data?.url
        }
  })
  .catch((err) => {
      console.log(err.response.data.error);
      swal({
        title: "Error",
        text: err.response.data.error ,
        icon: "error",
        button: "Cancel",
      });
    });
}

export const getAds = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI()+`/ad/list` ,data, config) 
  .then(res=> {
      if(res.data){
          dispatch({
              type: GET_ADS,
              payload: res.data.data
          })
      }else{
          dispatch({
              type: GET_ADS,
              payload: res.data.data
          })
      }
  })
  .catch((err) => {
      swal({
          title: "Error" ,
           text:err.response.data.error,
          icon: "error",
          button: "Cancel",
        });
  });
}

export const getBlog = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI()+`/blog/list` ,data, config) 
  .then(res=> {
      if(res.data){
          dispatch({
              type: GET_BLOG,
              payload: res.data.data
          })
      }else{
          dispatch({
              type: GET_BLOG,
              payload: res.data.data
          })
      }
  })
  .catch((err) => {
      swal({
          title: "Error" ,
           text:err.response.data.error,
          icon: "error",
          button: "Cancel",
        });
  });
}

export const getWebImg = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI()+`/website-image/list` ,data, config) 
  .then(res=> {
      if(res.data){
          dispatch({
              type: GET_WEB_IMAGE,
              payload: res.data.data
          })
      }else{
          dispatch({
              type: GET_WEB_IMAGE,
              payload: res.data.data
          })
      }
  })
  .catch((err) => {
      swal({
          title: "Error" ,
           text:err.response.data.error,
          icon: "error",
          button: "Cancel",
        });
  });
}

//  GET CATEGORY
export const getCategory = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI()+`/nutrition/category/list`,data, config) 
  .then(res=> {
    console.log("Res",res)
      if(res.data){
          dispatch({
              type: GET_CATEGORY,
              payload: res.data.data
          })
      }else{
          dispatch({
              type: GET_CATEGORY,
              payload: res.data.data
          })
      }
  })
  .catch((err) => {
      swal({
          title: "Error" ,
           text:err.response.data.error,
          icon: "error",
          button: "Cancel",
        });
  });
}


export const CancelSubscription = (data) => (dispatch) => {
  axios
  .post(Constant.getAPI() + `/subscription/cancelWeb`, data, config)
  .then((res) => {
      console.log("API ", res.data);
      if(res?.data?.success){
        swal({
            title: 'Updated',
            text: "Updated Successfully",
            icon: "success",
            button: "ok",
          });
          // console.log("URL", 
        }
  })
  .catch((err) => {
      console.log(err.response.data.error);
      swal({
        title: "Error",
        text: err.response.data.error ,
        icon: "error",
        button: "Cancel",
      });
    });
}