import React, { useEffect, useState } from "react";
import { onHomePage , getAds,onPackageProgram , getWebImg } from "../redux/MainAction";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeVideo from "./HomeVideo";

const Home = () => {
  var AuthToken = localStorage.getItem("AuthToken")
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';
  const dispatch = useDispatch();
  const HomeData = useSelector((state) => state?.HitFitSite?.homeDetails);
  const ProgramList = useSelector(state=>state?.HitFitSite?.program)
  const HomeAds = useSelector((state) => state?.HitFitSite?.ads);
  const WEbImgs = useSelector((state) => state?.HitFitSite?.webImg);

  const [home, setHome] = useState();

  useEffect(() => {
    getCetegory();
    getRoleList()
    getList()
    getWebImg()
  }, []);

  const getList = () => {
    if(log){
      var data = {
        isWebsite:true,
      }
      dispatch(onPackageProgram(data))
    }
  }
  const getRoleList = () =>{
    var data = {
        // "status":true
    }
    dispatch(getAds(data))
    dispatch(getWebImg(data))
}
  var log = "true";
  const getCetegory = () => {
    if (log) {
      var data = {
        platform: "google",
      };
      dispatch(onHomePage(data));
    }
  };

  useEffect(() => {
    if (HomeData) {
      setHome(HomeData);
    }
  }, [HomeData]);

  console.log("WEbImgs", WEbImgs);
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 9000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings3 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <HomeVideo/> 
      <section className="strip">
          <div className="strip_add">
            <span class="fa fa-star checked"></span>
            {/* <p>{HomeAds[0]?.text} <u>START NOW</u></p> */}
            <div className="blogText" style={{ direction: lang !== "en" ? "rtl" : "" }}
                      dangerouslySetInnerHTML={{ __html: HomeAds[0]?.text }}
                    />
            <p></p>
          </div>
      </section>

      <section className="workout-section-men">
        <img
          src="images/2.png"
          className="img-fluid men-top-left"
          alt=""
        />
        <div className="padding-left-right">
          <div className="container-fluid">
            <h1>{lang=='en'? "Why doing Hiitfit Workouts?" : "لماذا تمارين هيت فت؟"}</h1>
            <div className="row d-flex text-center">
              <div className="col-lg-4 col-md-4 col-sm-12"></div>
              <div className="col-lg-4 col-md-8 col-sm-12">
                <div className="workout-listing">
                  {lang === "en" ? (
                    <ol>
                      <li>Fastest way to burn fat.</li>
                      <li className="list-1">
                        Increase in your overall fitness level.
                      </li>
                      <li>Increase your metabolism.</li>
                      <li className="list-1">
                        You will gain amazing knowledge in fitness & in HIIT
                        Workouts.
                      </li>
                      <li>
                        Increase in speed, power, coordination, strength &
                        agility.
                      </li>
                      <li className="list-1">You will burn your BELLY FAT.</li>
                      <li>You will gain muscle mass.</li>
                      <li className="list-1">
                        You will be able to perform all your life activities in
                        an effective & efficient way.
                      </li>
                      <li>
                        After practicing HIIT FIT workouts, any other workout
                        will be easy.
                      </li>
                      <li className="list-1">Huge increase in your stamina.</li>
                    </ol>
                  ) : (
                    <ol className="araList" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                      <li>أسرع طريقة لحرق الدهون.</li>
                      <li className="list-1">زيادة في مستوى لياقتك العامة.</li>
                      <li>زيادة التمثيل الغذائي الخاص بك.</li>
                      <li className="list-1">
                        سوف تكتسب معرفة مذهلة في اللياقة البدنية وفي تمارين
                        الهيت
                      </li>
                      <li>زيادة السرعة والقوة والتنسيق والقوة وخفة الحركة.</li>
                      <li className="list-1">سوف تحرق دهون البطن.</li>
                      <li>سوف تكسب كتلة عضلية.</li>
                      <li className="list-1">
                        ستكون قادرًا على أداء جميع أنشطة حياتك بطريقة فعالة
                        وفعالة.
                      </li>
                      <li>
                        بعد ممارسة تمارين HIIT FIT ، سيكون أي تمرين آخر سهلاً.
                      </li>
                      <li className="list-1">
                        زيادة هائلة في القدرة على التحمل.
                      </li>
                    </ol>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="workout-section-women">
        <div className="container">
          {lang === "en" ? (
            <h1>What is HIIT?</h1>
          ) : (
            <h1 style={{ direction: lang !== "en" ? "rtl" : "" }}>
            ما هو هيت فت ترانسفورميشن؟
            </h1>
          )}
          <div className="row d-flex text-center">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="workout-listing-women">
                <img
                  src="images/question-mark.webp"
                  className="img-fluid question-mark"
                  alt=""
                />
                {lang === "en" ? (
                  <>
                    <h5>Simply</h5>
                    <p>
                      it is an application and website for HIIT exercises,
                      <br />
                      in exchange for a very simple monthly or annual
                      subscription, <br />
                      through which you can access all programs and diets plan
                    </p>
                    <br />
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    <p>
                      ببساطة هو تطبيق و موقع الكتروني خاص بتمارين الهيت بمقابل
                      اشتراك شهري او سنوي بسيط جدا تستطيع به الوصول الى جميع
                      البرامج و الانظمة الغذائية
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <img
          src="images/women-bottom-right.webp"
          className="img-fluid women-bottom-left"
          alt=""
        />
      </section>

      {/* <section className="basic">
        <div className="container text-center">
          <h4>Basic Hiit Chalenge</h4>
          <div className="row">
            <div className="col-3 col-lg-3 col-md-3 col-sm-3">
              <div className="hiit-line">
                <img
                  src="images/basic-line.png"
                  className="img-fluid basic-line"
                  alt=""
                />
                <h1>
                  45 <span className="basic-days">DAYS</span>
                  <span className="basic-hiit">Of Hiit !!</span>{" "}
                </h1>
                <a href="javascript:void(0)">Hiit It Now </a>
              </div>
            </div>
            <div className="col-9 col-lg-9 col-md-9 col-sm-9">
              <p>
                Just because you’re new to fitness doesn’t mean high-intensity
                interval training isn’t for you. Otherwise known as HIIT
                workouts, these fast-paced routines have been shown to torch
                tons of calories in a short amount of time — so you don’t need
                to spend hours in the gym. This type of training will have you
                alternating between periods of maximum effort (think: 20 seconds
                of jumping jacks) and short recovery.
              </p>
              <p>
                If you’re just getting into fitness — or starting over after an
                injury — the key to success lies in doing the right moves, at
                your own pace. Yes, HIIT workouts should be intense, but pushing
                too hard, too fast can result in injuries and other setbacks.
                Your task: Listen to your body, modify as needed, and complete
                each movement with proper form.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      <section className="healthy-lifestyle" style={{marginTop:"5rem"}}>
        <div className="container text-center">
          {lang === "en" ? (
            <h2>Healthy Lifestyle</h2>
          ) : (
            <h2 style={{ direction: lang !== "en" ? "rtl" : "" }}>
              أسلوب حياة صحي
            </h2>
          )}
          <div className="row d-flex justify-content-center text-center">
            <div className="col-12">
              {lang === "en" ? (
                <p>
                  Discover the fun training style of our trainers through the
                  various HIIT programs that will become fitness and health a
                  lifestyle{" "}
                </p>
              ) : (
                <p style={{ direction: lang !== "en" ? "rtl" : "" }}>
                  اكتشف أسلوب التدريب الممتع لمدربينا من خلال برامج الهيت
                  المتنوعة التي من خلالها ستصبح الياقة والصحة أسلوب حياة
                </p>
              )}
              <div className="healthy-lifestyle-img" style={{backgroundImage: `url(images/new.png)`,}}>
                
                {/* <img
                  src="images/Large.png"
                  className="img-fluid"
                  alt=""
                /> */}
                {/* <a href="javascript:void(0)">
                  <img
                    src="images/play-icon.png"
                    className="img-fluid play-icon"
                    alt=""
                  />
                </a> */}
              </div>
            </div>
            <div className="sweat-div"></div>
            {lang === "en" ? (
              <h2>Burn fat, build muscle, and get fitter</h2>
            ) : (
              <h2 style={{ direction: lang !== "en" ? "rtl" : "" }}>
                احرق الدهون وابني العضلات وارفع لياقتك
              </h2>
            )}
            <div className="col-lg-6">
              <div className="sweat-div-col">
                {lang === "en" ? (
                  <>
                    <p>
                      Subscribe and get unlimited access to our growing program
                      of HIIT workouts in our website and app, and do as many
                      workouts as you want anytime and anywhere.{" "}
                    </p>
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    <p style={{ direction: lang !== "en" ? "rtl" : "" }}>
                      اشترك واحصل على وصول غير محدود إلى برامجنا المتنامية
                      لتدريبات الهيت في موقعنا والتطبيق ، وقم بعمل العديد من
                      التدريبات كما تريد في أي وقت و أي مكان
                    </p>
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="workouts-videos">
            <div className="row justify-content-center text-center g-3">
              {ProgramList?ProgramList?.map((prog, idx)=>(
                <div className="col-lg-6 col-md-6 col-sm-12" key={idx}>
                  <div className="hover-mask">
                    <a href={`/workouts-plan/` + prog?.id} >
                      <img
                        src={prog?.MainImage?.url}
                        className="img-fluid"
                        alt=""
                      />
                      <h2>
                        <img
                          src="images/play-icon.png"
                          className="img-fluid workout-play-icon"
                          alt=""
                        />
                      </h2>
                    </a>
                    <h4 className="text-center">{prog?.title}</h4>
                    <p>
                     {prog?.description}
                    </p>
                    <button type="button" className="btn btn-primary workout-btn">
                      <a href={`/workouts-plan/` + prog?.id}>See Video</a>
                    </button>
                  </div>
                </div>
                ))
                : ""
              }
              {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="hover-mask">
                  <a href="javascript:void(0)" title="">
                    <img
                      src="images/workout-2.webp"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      <img
                        src="images/play-icon.png"
                        className="img-fluid workout-play-icon"
                        alt=""
                      />
                    </h2>
                  </a>
                  <h4 className="text-center">EXCERCISES</h4>
                  <p>
                    Subscribe and receive unlimited access to the growing
                    library of HIIT classes in the HIIT FIT program, do as many
                    classes as you want, at anytime.
                  </p>
                  <button type="button" className="btn btn-primary workout-btn">
                    <a href="javascript:void(0)">See Video</a>
                  </button>
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="hover-mask">
                  <a href="javascript:void(0)" title="">
                    <img
                      src="images/workout-3.webp"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      <img
                        src="images/play-icon.png"
                        className="img-fluid workout-play-icon"
                        alt=""
                      />
                    </h2>
                  </a>
                  <h4 className="text-center">HIIT LIVES</h4>
                  <p>
                    Subscribe and receive unlimited access to the growing
                    library of HIIT classes in the HIIT FIT program, do as many
                    classes as you want, at anytime.
                  </p>
                  <button type="button" className="btn btn-primary workout-btn">
                    <a href="javascript:void(0)">See Video</a>
                  </button>
                </div>
              </div> */}
              {/* <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="hover-mask">
                  <a href="javascript:void(0)" title="">
                    <img
                      src="images/workout-4.webp"
                      className="img-fluid"
                      alt=""
                    />
                    <h2>
                      <img
                        src="images/play-icon.png"
                        className="img-fluid workout-play-icon"
                        alt=""
                      />
                    </h2>
                  </a>
                  <h4 className="text-center">DUMBELLS</h4>
                  <p>
                    Subscribe and receive unlimited access to the growing
                    library of HIIT classes in the HIIT FIT program, do as many
                    classes as you want, at anytime.
                  </p>
                  <button type="button" className="btn btn-primary workout-btn">
                    <a href="javascript:void(0)">See Video</a>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="hiitfit-team">
        <div className="container">
          {lang == 'en'?
            <h2>Hiitfit Team</h2>
          :
            <h2>فريق هيت فت</h2>
          }
          <div className="row justify-content-center text-center">
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-3.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang=='en'? "Mohammed Hani": 'محمد هاني'}</h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-1.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang=='en'?"Ahmed":'احمد'}</h4>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-5.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang=='en'? "Noor": "نور"}</h4>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center">
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-2.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang=='en'? "AK":"عبدالسلام"}</h4>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-4.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang =='en' ? "Ralph" : "رالف"}</h4>
              </div>
            </div>
            <div className="col-6 col-lg-4 col-md-6 col-sm-6">
              <div className="team-col">
                <img
                  src="images/teams/team-6.webp"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang=='en' ? "Chelsa": 'شيلسي'}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials">
        <div className="container">
          <h2>{lang==='en'? "What did they say about us?" : "ماذا قالوا عنا ؟"}</h2>
          <div className="swiper events-swiper animate__animated ">
            <div className="swiper-wrapper swiper-wrapper-testimonials">
              <div className="swiper-slide">
               {lang==="en"? <Slider {...settings2}>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Hussein Mustafawi</h3>
                      <span>Complete change in my life</span>
                    </div>
                    <p>
                      This website and application helped me reach my dreams and
                      changed the nature of my life for the better... Currently,
                      I am in high fitness and enjoy good health, and the
                      passion and love for exercise has increased to get better
                      results... And most of all, I can access the exercises
                      from anywhere And anytime I need them.
                    </p>
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Mohammad Arabi</h3>
                      <span>My life has changed</span>
                    </div>
                    <p>
                      I always say that I do not find time while I am busy, but
                      after participating in and committing to training within
                      the schedule allocated to the program, my whole life
                      changed and I found that I had enough time to train and I
                      was able to burn fat and gain high fitness and so far I
                      have lost about 20 kilograms of my weight.
                    </p>
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Hussein Dashti</h3>
                      <span>The perfect choice for you!</span>
                    </div>
                    <p>
                      I am a person who is afraid of envy. This program provided
                      me with the ability to choose the environment that is
                      suitable for me and my soul without anyone with me. I used
                      to exercise in my room with all my comfort, without anyone
                      looking at me and focusing on me, and God forbid I would
                      be injured.
                    </p>
                  </div>

                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Maha Al-Zankawi</h3>
                      <span>The Hit Fit app changed my life</span>
                    </div>
                    <p>
                      The application helped me very much by stopping my going
                      to physical therapy. The exercises are well thought out
                      and treat health problems that I used to suffer from. I
                      can’t do without the program, and the best thing about it
                      is that the time is short, and the exercise is light and
                      renewable, and it doesn’t make me feel bored.
                    </p>
                  </div>

                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Rawan Rashed</h3>
                      {/* <span>The perfect choice for you!</span> */}
                    </div>
                    <p>
                      HIIT FIT TRANSFORMATION is an amazing program that changed
                      my life to the better, with an amazing variety of workouts
                      that can be preformed at any place and any time. And of
                      course with the guidance of such incredible and
                      professional trainers.highly recommended.
                    </p>
                    <br />
                  </div>

                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Ahmed Mustafa</h3>
                      <span>Transform my life</span>
                    </div>
                    <p>
                      I feel better, healthier and fitter than I have in years
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Alaa</h3>
                      {/* <span>The perfect choice for you!</span> */}
                    </div>
                    <p>
                      The application is very nice, especially you speak Arabic.
                      We can understand you very well, bravo
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Natalie</h3>
                      <span>The application is addictive!!!🔥😍</span>
                    </div>
                    <p>
                      The first thing I was hesitant to download, but when I
                      downloaded it and subscribed for a month, I did not
                      expect, frankly, that I will complete the fourth month
                      with you
                    </p>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Hassan Al Bakri</h3>
                      <span>Finally... I did it</span>
                    </div>
                    <p>
                      In a few words... Before HIITFIT is not the same as after
                      it... I learned how to be an athletic person and live a
                      healthy life so that this is my daily life approach. And
                      my identity by which I identify myself... Hassan, the
                      athlete with a healthy life.. which I will continue to do
                      for life
                    </p>
                    <br />
                  </div>

                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Zeyad</h3>
                      <span>New healthy lifestyle</span>
                    </div>
                    <p>
                      An easy exercise suitable for me. It is available with me
                      in all places and I exercise at any time that suits me.
                      Help me overcome the excuses I tell myself not to
                      exercise, such as: there is no time or the world is
                      crowded and hot, and I do not have the energy to go to the
                      gym. In addition to the previous points on a very
                      important point, I tried many clubs that I did not adhere
                      to because the regular exercise for the GYM needed a long
                      time and my time was limited and I could not exercise for
                      long periods that roamed the hour and for these reasons
                      HIITFIT transformation changed my life for the better and
                      the exercise became part of my daily routine that gives me
                      energy And positive feelings despite all the pressures of
                      life
                    </p>
                  </div>

                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Ali Al-Zankawi</h3>
                      <span>Change my life for the better</span>
                    </div>
                    <p>
                      The app helped me lose 19 kilos and changed my life for
                      the better. I can practice the app's exercises anywhere
                      and anytime.
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box">
                    <div className="aboutSay">
                      <h3>Suaad Ali</h3>
                      <span>Best decision I made</span>
                    </div>
                    <p>
                      The idea of starting any training was impossible because I
                      felt the difficulty of exercise, but with the Heat Fit
                      application, my outlook changed because I found
                      alternative exercises that I could start with, and with
                      time my fitness increased, and I began to like to exercise
                      more and more, especially since the exercises are within
                      my reach, so I can exercise at any time. Very proud of
                      myself now
                    </p>
                  </div>
                </Slider>
                :
                <Slider {...settings2} >
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>حسين مصطفوي</h3>
                      <span>التغيير الكامل في حياتي</span>
                    </div>
                    <p>
                    ساعدني هذا الموقع و التطبيق في الوصول لأحلامي و غير طبيعة حياتي للافضل حاليا لدي لياقه عالية و اتمتع بصحة جيدة و زاد الشغف و حب التمرين للحصول على نتائج افضل ... و الاهم من هذا كله ان اقدر ادخل على التمارين من اي مكان و في اي وقت احتاجهم
                    </p>
                  </div>
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>محمد عربي</h3>
                      <span>حياتي تغيرت</span>
                    </div>
                    <p>
                    دائما  اقول لا أجد وقت وانا مشغول، لكن بعد الاشتراك والتزامي بالتدريب ضمن الجدول المخصص للبرنامج ، حياتي كلها تغيرت ووجدت انه لدي الوقت الكافي لأتدرب واستطعت ان احرق دهون واكسب لياقة عالية وخسرت حتى الآن بحدود ٢٠ كيلوجرام من وزني
                    </p>
                  </div>
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>حسين دشتي</h3>
                      <span>	الخيار الأمثل لي</span>
                    </div>
                    <p>
                    انا شخص اخاف من الحسد، هذا البرنامج وفر لي اني اختار البيئة المناسبة لي وبروحي بدون ما يكون احد معاي، كنت اتمرن بغرفتي وبكل اريحيه بدون ما احد يطالعني ويكون مركز فيني ولا سمح الله تحوشني اصابة
                    </p>
                  </div>

                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>مها الزنكوي </h3>
                      <span>أبلكيشن الهيت فت غير حياتي</span>
                    </div>
                    <p>
                    ساعدني جدًا الأبلكيشن بالتوقف عن ذهابي للعلاج الطبيعي التمارين مدروسة واتعالج مشاكل صحية كنت اعاني منها مااقدر استغنى عن البرنامج واحسن شي فيه المدة الزمنية قليلة والتمرين خفيف ومتجدد ومايحسسني بالملل
                    </p>
                  </div>

                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>روان راشد</h3>
                      {/* <span>The perfect choice for you!</span> */}
                    </div>
                    <p>
                    هيت فيت ترانسفورميشن هو برنامج رائع غير حياتي إلى الأفضل ، مع مجموعة متنوعة مذهلة من التدريبات التي يمكن إجراؤها في أي مكان وفي أي وقت. وبالطبع بتوجيه من هؤلاء المدربين الرائعين والمحترفين 
 أوصي به بشدة
                    </p>
                    <br />
                  </div>

                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>أحمد مصطفى</h3>
                      <span>حول حياتي </span>
                    </div>
                    <p>
                    أشعر بأفضل حال وصحة و لياقة لم اشعر بها منذ سنوات
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>الاء</h3>
                      {/* <span>The perfect choice for you!</span> */}
                    </div>
                    <p>
                    حلو جدا التطبيق خصوصا تتكلمون عربي نقدر نفهم عليكم زين برافو عليكم
                    </p>
                    <br />
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>نتالي</h3>
                      <span>التطبيييق ادمااااان!!!🔥😍</span>
                    </div>
                    <p>
                    اول شي كنت متردده انزله بس لما حملته و اشتركت شهر ما توقعت صراحه الحين بكمل الشهر الرابع معكم
                    </p>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>حسان البكري</h3>
                      <span>أخيرا فعلتها</span>
                    </div>
                    <p>
                    بكلمات قليلة ... ما قبل HIITFIT ليس كما بعده .. فقد تعلمت كيف اكون انسان رياضي واعيش حياة صحية ليكون ذلك منهج حياتي اليومية. وهويتي التي اعرف بها عن نفسي... حسان الرياضي ذو الحياة الصحية.. والذي سأستمر عليه مدى الحياة
                    </p>
                    <br />
                  </div>

                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>زياد</h3>
                      <span>نمط حياة صحي جديد </span>
                    </div>
                    <p>
                    تمرين سهل مناسب لي متوفر معاي بكل الأماكن وأتمرن بأي وقت يناسبني. ساعدني ان اتجاوز الأعذار اللي أقولها لنفسي عشان ما اتمرن مثل : ما في وقت او الدنيا زحمه و حر وما الي خلق اروح الجيم. وبالاضافة للنقاط الماضية في نقطه مهمة جدا، انا جربت اندية كثير ماكنت التزم لانه التمرين العادي للنادي كان يحتاج لوقت طويل و انا وقتي محدود وما اقدر اتمرن لفترات طويلة تطوف الساعه ولهاذي الاسباب HIITFIT transformation غير حياتي للافضل و صار التمرين جزء من روتيني اليومي اللي يعطيني طاقة ومشاعر ايجابية رغم كل الضغوط من الحياة
                    </p>
                  </div>

                  <div className="testimonials-box" style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>علي الزنكوي</h3>
                      <span>غير حياتي الى الافضل</span>
                    </div>
                    <p>
                    التطبيق ساعدني على خسارة ١٩ كيلو و غيرت حياتي الى الافضل ، استطيع ان اتدرب تدريبات التطبيق في أي مكان و أي وقت
                    </p>
                    <br />
                    <br />
                  </div>
                  <div className="testimonials-box " style={{ direction: lang !== "en" ? "rtl" : "" }}>
                    <div className="aboutSay">
                      <h3>سعاد علي</h3>
                      <span>افضل قرار اتخذته</span>
                    </div>
                    <p>
                    فكرة اني ابدأ أي تدريب كانت مستحيلة بسبب شعوري بصعوبة التدريبات الرياضية لكن مع تطبيق هيت فت تغيرت نظرتي لاني وجدت التمارين البديلة اللي استطيع ان ابدأ فيها ومع الوقت ارتفعت لياقتي و صرت احب اتمرن اكثر واكثر بالذات ان التدريبات في متناول ايدي فأستطيع التمرن في أي وقت , فخور جدا بنفسي الان
                    </p>
                  </div>
                </Slider>
                }
              </div>

            </div>
            
            <div className="swiper-pagination swiper-pagination-events"></div>
          </div>
        </div>
      </section>

      <section className="program-works">
        <div className="container">
          {lang === "en" ? (
            <h2>Proof Our Programs Work</h2>
          ) : (
            <h2 style={{ direction: lang !== "en" ? "rtl" : "" }}>
              إثبات عمل برامجنا
            </h2>
          )}
          {lang == "en" ? (
            <>
              <p>
                These are people who challenged their reality and started
                practicing and training within our
                <br /> center and followed our training within the website and
                application
              </p>
              <br />
            </>
          ) : (
            <>
              <p style={{ direction: lang !== "en" ? "rtl" : "" }}>
                هؤلاء هم الأشخاص الذين تحدوا واقعهم وبدأوا في الممارسة والتدريب
                داخل مركزنا واتبعوا تدريبنا داخل الموقع والتطبيق
              </p>
              <br />
            </>
          )}
          <div className="row">
            <>
              <Slider {...settings} className="">
               {WEbImgs?.map((item) => (
                  <div className="col-6 col-lg-3 col-md-6 col-sm-6">
                    <div className="before-after webImg ">
                      {/* <a href="javascript:void(0)" title=""> */}
                        <img
                          src={lang == 'en'?item?.MediaEn?.url :item?.MediaAr?.url}
                          className="img-fluid"
                          alt="Before-After"
                        />
                        <h4>{item?.title}</h4>
                      {/* </a> */}
                    </div>
                  </div>
                ))}
              </Slider>
            </>
          </div>
            
        </div>
      </section>

      <section className="live-cast-head">
        <div className="container">
          <h2>{lang==='en'? "Live Cast":"البث المباشر"}</h2>
        </div>
      </section>

      <section className="live-cast">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="live-cast-col">
                <img
                  src="images/live-cast-video-img.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="live-cast-col-1">
                <img
                  src="images/hiitfit-logo-top-white.svg"
                  className="img-fluid"
                  alt=""
                />
                <h4>{lang==='en'? "Live Cast":"البث المباشر"}</h4>
              </div>
            </div>
          </div>
          <div className="join-cast">
            <a href="javascript:void(0)">{lang==='en'? "Coming Soon":"قريباً"} !!</a>
          </div>
          <div className="live-cast-text">
            <p>
             {lang==='en'? " It is possible to join the live broadcast programs with a different subscription. Each program has a special price." :"من الممكن الانضمام إلى برامج البث المباشر باشتراك مختلف. كل برنامج له سعر خاص." }
            </p>
          </div>
        </div>
      </section>

      <section className="nutrition-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <ul className="gallery-main">
                <li>
                  <a href="images/nutrition-1.webp" data-fancybox="images">
                    <img
                      src="images/nutrition-1.webp"
                      className="img-fluid"
                      alt="Gallery"
                    />
                  </a>
                </li>
                <li>
                  <a href="images/nutrition-2.webp" data-fancybox="images">
                    <img
                      src="images/nutrition-2.webp"
                      className="img-fluid"
                      alt="Gallery"
                    />
                  </a>
                </li>
                <li>
                  <a href="images/nutrition-3.webp" data-fancybox="images">
                    <img
                      src="images/nutrition-3.webp"
                      className="img-fluid"
                      alt="Gallery"
                    />
                  </a>
                </li>
                <li>
                  <a href="images/nutrition-4.webp" data-fancybox="images">
                    <img
                      src="images/nutrition-4.webp"
                      className="img-fluid"
                      alt="Gallery"
                    />
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="nutrition-text">
                <h2>{lang==="en"? "Nutrition Center":"مركز التغذية"}</h2>
                <ul className="nutrition-listings">
                  {lang === 'en'? <li>
                    We have free nutrition plans that are consistent with our
                    training programs
                  </li>
                  :
                  <li>لدينا خطط تغذية مجانية تتوافق مع برامجنا التدريبية</li>}
                  <li>{lang==='en'? "Available now with subscription" :"متوفر الآن مع الاشتراك"}</li>
                </ul>
              </div>
              <div className="nutrition-signup">
                {AuthToken==null ? <a href="/sign-up">{lang==='en'? "Sign Up" :" سجل الان"}</a> : ""}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="motivate-join">
        <div className="container">
          <h2>{lang==="en"? "Results Starts Here" :"النتائج تبدأ هنا"}</h2>
          <div className="motivate-main">
            <img
              src="images/motivate/banner.jpeg"
              className="img-fluid"
              alt=""
            />
            <div className="motivate-results">
            {AuthToken==null ? <a href="/sign-up">{lang==='en'? "Sign Up" :" سجل الان"}</a> : ""}
            </div>
          </div>
        </div>
        {/* <div className="mt-5" >
          {home?.packages?.map((pack)=>((
            <>
            <h1 className="top-content container-fluid justify-content-center d-flex text-center fw-bold">{pack?.title}</h1>
            <Slider {...settings2} >
              {pack?.Programs?.map((item)=>(
                <div className="pcCont  container-fluid justify-content-center d-flex text-center">
                  <div className="packImage container-fluid justify-content-center d-flex text-center" style={{backgroundImage:`url(${item?.MainImage?.url})`}}>
                    <h4>{item?.title}</h4>
                  </div>
              </div>

              ))}
            </Slider>
            </>
          ))) 
          }
        </div> */}
        <div className="mt-5">
          {home?.hashtags?.map((hash) => (
            <>
              <h1 className="top-content container-fluid justify-content-center d-flex text-center fw-bold mt-5">
                {hash?.hashtag}
              </h1>
              <Slider {...settings3} className="">
                {hash?.medias?.map((item) => (
                  <div className=" container-fluid justify-content-center d-flex text-center">
                    <div
                      className="hash"
                      style={{
                        backgroundImage: `url(${item?.url})`,
                        backgroundPosition: "center",
                        backgroundOrigin: "content-box",
                        backgroundSize: "cover",
                        width: "100%",
                      }}
                    ></div>
                  </div>
                ))}
              </Slider>
            </>
          ))}
        </div>
      </section>

      <section className="faq">
        <div className="container">
          {lang==="en"?<h4>
            Fast Q & A<br />
            Most Popular Questions
          </h4> : <h4>سؤال وجواب سريعالأسئلة الأكثر شيوعا</h4>}
          <div className="col-12">
            {lang === "en" ? (
              <div className="accordion" id="accordionExample">
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Q : Is there an app for hitfittransformation.com?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: Yes, there is an application for Apple and Android
                        devices that includes more than one program and many
                        exercises.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Q: Can I download the workouts and watch them offline?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: Yes, the workouts can be downloaded via the app so
                        you can watch them offline
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Q: How will I find out which program is right for me?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: For each program, there is an introductory video,
                        which is an explanation of the program with success
                        stories for this program, with a detailed description of
                        the program and its level. Each program has 3 levels
                        (low intensity - medium intensity - high intensity).
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Q: Is my subscription to the site linked with the
                      applications?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: Yes, by subscribing to the website, you can access
                        the application, you only have to log in
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Q: Are there fitness programs for beginners?
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : Yes! The intensity level of each program can be
                        easily selected and there are many options for
                        beginners. In addition, our programs also include a
                        modified option to help you get started regardless of
                        your fitness level, be it beginner, intermediate or
                        senior.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Q: What nutritional content is available?
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : As part of your membership in
                        Hiitfittransformation.com, you will have access to a
                        nutrition center that contains many nutritional
                        programs, including those that suit your sports program,
                        and there are special programs for women and men Example
                        (The Hiit25 program has a diet program for women and a
                        diet for men)
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Q: Will I get results from my subscription with you (or
                      quick results)
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : Yes, because the heat programs are designed to
                        obtain the best possible results in general fitness,
                        muscular fitness, and fat burning, so that all elements
                        of physical fitness will be trained. Our programs are
                        comprehensive transformation programs in fitness and
                        body shape, and we have many success stories What is
                        required of you is application and continuity with
                        interest in a healthy diet.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Q: What does the subscription include?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: Any subscription, whether monthly or annual, gives
                        you full access to all recorded programs and diets
                        compatible with our programs
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="accordion"
                id="accordionExample"
                style={{ direction: lang !== "en" ? "rtl" : "" }}
              >
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Q : هل يوجد تطبيق لموقع hiitfittransformation.com ؟
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: نعم هناك تطبيق لأجهزة أبل و أندرويد يتضمن أكثر من
                        برنامج و ألكثير من التمارين
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      Q: هل يمكنني تنزيل التدريبات ومشاهدتها في وضع عدم الاتصال؟
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: نعم ، يمكن تنزيل التدريبات عبر التطبيق حتى تتمكن من
                        مشاهدتها دون اتصال بالإنترنت
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      Q: كيف سأكتشف البرنامج المناسب لي؟
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: يوجد لكل برنامج فيديو تعريفي عبارة عن شرح للبرنامج مع
                        قصص النجاح لهذا البرنامج مع وصف تفصيلي للبرنامج و مستواه
                        ويوجد لكل برنامج 3 مستويات (منخفض الشدة – متوسط الشدة –
                        عالي الشدة )
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      Q: هل اشتراكي في الموقع مربوط مع التطبيقات ؟
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: نعم عن طريق اشتراكك بالموقع تستطيع الدخول على التطبيق
                        فقط عليك تسجيل الدخول
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Q: هل توجد برامج لياقة للمبتدئين؟
                    </button>
                  </h2>
                  <div
                    id="collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSix"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : نعم! يمكن تحديد مستوى شدة كل برنامج بسهولة و يوجد
                        العديد من الخيارات للمبتدئين ، بالإضافة إلى ذلك تشتمل
                        برامجنا أيضًا على خيار معدل لمساعدتك على البدء بغض النظر
                        عن مستوى لياقتك سواء كان مبتدأ أو متوسط أو عالي.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingSeven">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Q: ما هو المحتوى الغذائي المتاح؟
                    </button>
                  </h2>
                  <div
                    id="collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingSeven"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : كجزء من عضويتك في Hiitfittransformation.com ، ستتمكن
                        من الوصول إلى مركز التغذية الذي يحتوي على العديد من
                        البرامج الغذائية منها التي تتناسب مع برنامجك الرياضي و
                        توجد برامج خاصة للنساء و للرجال مثال (برنامج Hiit25 يوجد
                        فيه برنامج غذائي خاص بالنساء و برنامج غذائي خاص بالرجال
                        )
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingEight">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      Q: هل سأحصل على نتائج من اشتراكي معكم (أو نتائج سريعة)
                    </button>
                  </h2>
                  <div
                    id="collapseEight"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingEight"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A : نعم لان البرامج الهيت مصممة للحصول على أفضل نتائج
                        ممكنه في اللياقة العامة واللياقة العضلية و حرق الدهون
                        بحيث سيتم تدريب جميع عناصر اللياقة البدنية وبرامجنا
                        عبارة عن برامج تحويل شامل في اللياقة و شكل الجسم ولدينا
                        العديد من قصص النجاح و المطلوب منك التطبيق و الاستمرارية
                        مع الاهتمام بنظام غذائي سليم
                      </p>
                    </div>
                  </div>
                </div>

                <div className="accordion-item accordion-home-faq">
                  <h2 className="accordion-header" id="headingFive">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      Q: على ماذا يشمل الاشتراك؟
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFive"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        A: أي اشتراك سواء كان شهري أو سنوي يمنحك الوصول الكامل
                        الى جميع البرامج المسجلة و الانظمة الغذائية المتوافقة مع
                        برامجنا{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
