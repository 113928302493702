/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getNutritionMeal } from "../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { pdfjs } from "react-pdf";
import PdfComp from "./PdfComp";
import pdfWorker from "pdfjs-dist/build/pdf.worker.min.js";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

const ViewPdf = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const Meal = useSelector((state) => state?.HitFitSite?.NutritionMeal);

  const [pdf, setPDF] = useState("");

  useEffect(() => {
    getMeal();
  }, []);

  const getMeal = () => {
    var dat1 = {
      limit: 1000,
    };
    dispatch(getNutritionMeal(dat1));
  };
  useEffect(() => {
    if (param.id) {
      var mealFilter = Meal?.filter(
        (itm) => itm?.dietPlanId === parseInt(param.id)
      );
      console.log("mealFilter", mealFilter);
      setPDF(mealFilter ? mealFilter[0]?.pdf.url : "");
    }
  }, [param, Meal]);
  console.log("param", pdf);
  return (
    <section className="innerpage-section blogs">
      <div className="padding-left-right">
        <div className="container">
          <h2 className="diet-plan-head">View Meals</h2>
          <div className="tab-content" id="pills-tabContent">
            <div
              // class="tab-pane fade show active"
              id="pills-loose"
              // role="tabpanel"
              aria-labelledby="pills-loose-tab"
            >
              <div>
                {/* <PdfComp pdfFile={pdf} /> */}
                <DocViewer
                  documents={[{uri:pdf}]}
                  initialActiveDocument={{uri:pdf}}
                  pluginRenderers={DocViewerRenderers}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ViewPdf;
