import React from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import { FaTiktok } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => { 
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';

  const handleClick = () => {
    // Scroll to the top of the page with smooth animation
    scroll.scrollToTop();
  };
  return (
    <section className="footer-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-9 col-md-8 col-sm-12">
            <div className="footer-content">
              {lang ==="en"? <>
                <div className="col-last-one">
                  <br/>
                  <br/>
                  <p>
                  Results vary depending on starting point, goals, and effort. Exercise and proper diet are necessary to achieve and maintain weight loss and muscle definition.
                  </p>
                </div>
                <div className="col-last-two">
                  <p>
                  If you are pregnant or nursing, or if you have any special or unique medical condition, consult your physician and follow all safety instructions before starting any exercise program or using any supplement, nutrition plan, or meal replacement product.
                  </p>
                </div>
                <div className="col-last-three">
                  <p>
                  The contents on our website are for informational purposes only, and are not intended to treat or diagnose any medical condition or replace doctors advice
                  </p>
                </div>
              </>
              : <>
                <div className="col-last-one" >
                  <br/>
                  <br/>
                  <p  style={{direction: lang !== "en"?"rtl":""}} >
                  تختلف النتائج حسب نقطة البداية والأهداف والجهد. ممارسة الرياضة والنظام الغذائي السليم ضروريان لتحقيق والمحافظة على فقدان الوزن وتحديد العضلات.
                  </p>
                </div>
                <div className="col-last-two">
                  <p  style={{direction: lang !== "en"?"rtl":""}}>
                  إذا كنت حاملاً أو مرضعة أو إذا كنت تعاني من أي حالة طبية خاصة أو فريدة استشر طبيبك واتبع جميع تعليمات السلامة قبل البدء في أي برنامج تمارين أو استخدام أي مكمل أو خطة تغذية أو منتج بديل للوجبات
                  </p>
                </div>
                <div className="col-last-three">
                  <p  style={{direction: lang !== "en"?"rtl":""}}>
                  المحتويات الموجودة على موقعنا الإلكتروني هي لأغراض إعلامية فقط ، ولا تهدف إلى علاج أو تشخيص أي حالة طبية أو استبدال نصيحة الدكاترة 
                  </p>
                </div>
              </> }
              <ul className="social-footer">
                <li className="d-inline">
                  <Link to="https://www.instagram.com/hiitfit_transformation/?igsh=MWVmbmg5NzczNDIMw" target="_blank">
                    <i className="fab fa-instagram img-social img-instagram"></i>
                  </Link>
                </li>
                <li className="d-inline">
                  <Link to="https://www.facebook.com/profile.php?id=100066840212888&mibextid=opq0tG" target="_blank">
                    <i className="fab fa-facebook-square img-social img-facebook"></i>
                  </Link>
                </li>
                <li className="d-inline">
                  <Link to="https://twitter.com/i/flow/login?redirect_after_login=%2Fhiitfit_trans" target="_blank">
                      <FaXTwitter className="img-social"  style={{height:"20px"}} />
                  </Link>
                </li>
                <li className="d-inline">
                  <Link to="https://www.youtube.com/@hiitfittransformation" target="_blank">
                    <i className="fab fa-youtube img-social img-youtube"></i>
                  </Link>
                </li>
                <li className="d-inline">
                  <Link to="https://www.tiktok.com/@hiitfit_transformation?_t=8jLISp8UobP&_r=1" target="_blank">
                    <FaTiktok className="img-social"  style={{height:"20px"}}/>
                  </Link>
                </li>
              </ul>
              <ul className="social-footer lnks" >
                <Link to="/"  onClick={handleClick}>
                    <li className="d-inline" >
                       {lang==='en'? "Home" :"الصفحة الرئيسية"}
                    </li>
                </Link>
                <Link to="/contact_us" onClick={handleClick} >
                  <li className="d-inline">
                      {lang==='en'? "Contact Us":"اتصل بنا"}
                  </li>
                </Link>
                <Link to="/privacy-policy" onClick={handleClick} >
                  <li className="d-inline">
                    {/* <i className="fab fa-twitter img-social img-twitter"></i> */}
                   {lang==='en'? "Privacy Policy":"سياسة الخصوصية"} 
                  </li>
                </Link>
                <Link to="/tnc" onClick={handleClick}>
                  <li className="d-inline">
                    {/* <i class="fab fa-youtube img-social img-youtube"></i> */}
                    {lang==='en'? "Terms And Condition":"الشروط و الاحكام"}
                  </li>
                </Link>
                {/* <li className="d-inline">
                  <Link to="/" target="_blank">
                    <i className="fab fa-linkedin img-social img-linkedin"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="footer-logo">
              <img
                src="/images/hiitfit-logo-footer.svg"
                className="img-fluid"
                alt=""
              />
            </div>
            <div className="download-app">
              <h4>Download Our App</h4>
              <Link to="https://apps.apple.com/us/app/hiit-fit-transformation/id1564437301">
                <img src="/images/appstore.png" className="img-fluid" alt="" />
              </Link>
              <Link to="https://play.google.com/store/apps/details?id=com.hitfitapp.app" >
                <img
                  src="/images/google-play.png"
                  className="img-fluid"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
