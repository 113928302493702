import axios from "axios";
import swal from "sweetalert";
import Constant from "../../Constant";
import React, { useState } from 'react'
import { Link } from "react-router-dom";

const SignupWithEmail = () => {
  const [signup , setSignup ] = useState({
    email:""
  })
  const onHandleChange = (e) =>{
    const {value , name} = e.target
    setSignup({...signup,[name]:value})
  }
  const onSubmit = (e)=>{
    e.preventDefault()
    if(signup.email===""){
      swal({
        title: 'Error',
        text: `Pls Add Your Email`,
        icon: "error",
        button: "ok",
      });
      return false
    }
    var data ={
      email:signup?.email,
      loggedInWith:"normal",
      roleId:3,
    }

    axios
    .post(Constant.getAPI() + `/user/signup-web` , data)
    .then((res)=>{
      if(res?.data?.success){
        console.log( "WEB",res?.data);
        swal({
          title: 'SuccessFull',
          text: `Sign-Up Successfull`,
          icon: "success",
          button: "ok",
        });
        localStorage.setItem("isloggedIn", true);
        localStorage.setItem("email", res.data.data.email);
        localStorage.setItem("AuthToken", res.data.data.token);
        window.location.href="/subscribe-with-us"
      }
    })
    .catch((err) => {
      console.log(err.response);
      swal({
        title: "Error",
        text: err.response.data.message,
        icon: "error",
        button: "Cancel",
      });
    });
  }
  return (
    <>
       <section className="innerpage-section login-section">
        <div className="padding-left">
          <div className="container">
            <div className="login-content3">
              <h2>Welcome Back!</h2>
              <h3>Please Sign Up to continue your workout</h3>
              <div className="row g-0">
                <div className=" ">
                  <form className="row forms">
                    <div className="col-md-12 inpuEmail"> 
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="reg_email"
                          name="email"
                          value={signup?.email}
                          onChange={onHandleChange}
                          placeholder="Email"
                        />
                        <label for="reg-email">Email</label>
                      </div>
                    </div>
                  </form>
                  <br />
                  <br />
                  <div className="signup-btn2">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="button-submit hover-target"
                    >
                      Submit
                    </button>
                  </div>
                  {/* <br /> */}
                  {/* <span className="or-login">OR</span> */}
                  {/* <div className="login-with">
                    Sign In With
                    <a href="/login" className="login-link">
                      <img
                        src="images/google-plus.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 
    </>
  )
}

export default SignupWithEmail