/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch} from 'react-redux'
import {onLogin, onSignUpUser} from "../../redux/MainAction"
import axios from "axios";
import Constant from "../../Constant";
import swal from "sweetalert";

const Signup = () => {
  var emailStore = localStorage.getItem("email")
  var lang = localStorage.getItem("lang")===null ? "en" : localStorage.getItem("lang")
  const config = {
    headers: {
        "Content-Type": 'application/json',
        "Authorization": `Bearer ${localStorage.getItem("AuthToken")}` ,
        "Accept-Language": `${lang}`
    }
  }; 
  const dispatch = useDispatch()

  const [ register , setRegister ] = useState({
    // userName:"",
    fullName:"",
    email:"",
    password:"",
    confirmPassword:"",
    phone:"",
    gender:"",
  });

  const onHandleChange = (e) =>{
    const {value , name} = e.target
    setRegister({...register,[name]:value})
  }
  const onSubmit = (e) =>{
    e.preventDefault()

    if(register?.fullName===""){
      swal({
        title: 'Error',
        text: "Please Fill Your Full Name.",
        icon: "error",
        button: "ok",
      })
      return false
    } 
    if(register?.password===""){
      swal({
        title: 'Error',
        text: "Please Fill Your Password.",
        icon: "error",
        button: "ok",
      })
      return false
    }
    if(register?.password !== register?.confirmPassword){
      swal({
        title: 'Error',
        text: "Your Password & Confirm Password Not Matched.",
        icon: "error",
        button: "ok",
      })
      return false
    }

    var userData ={
      // userName: register?.userName,
      fullName:register?.fullName,
      email:emailStore,
      password:register?.password,
      confirmPassword:register?.confirmPassword,
      phone:register?.phone,
      // loggedInWith:"normal",
      // roleId:3,
      // gender: "male",
    }
    // dispatch(onSignUpUser(data))
    axios.put(Constant.getAPI() + `/user/edit-profile`, userData, config)
    .then((res)=>{
      if(res?.data?.success){
          // swal({
          //   title: 'SuccessFull',
          //   text: ``,
          //   icon: "success",
          //   button: "ok",
          // });
      handleDirectLogin(userData)
      }
    })
    console.log("USEr", userData);
  }

  const handleDirectLogin = (data)=>{
    var log = {
      email: data?.email,
      password: data?.password,
      loggedInWith:"normal",
    }
    dispatch(onLogin(log))
  }
  return (
    <>
      <section className="innerpage-section login-section">
        <div className="padding-left">
          <div className="container">
            <div className="login-content2">
              <h2>Welcome Back!</h2>
              <h3>Please continue your workout</h3>
              <div className="row g-0">
                <div className=" ">
                  <form className="row forms">
                    {/* <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="user_firstname"
                          name="userName"
                          value={register?.userName}
                          onChange={onHandleChange}
                          placeholder="User Name"
                        />
                        <label for="user_firstname">User Name</label>
                      </div>
                    </div> */}
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="reg_firstname"
                          value={register?.fullName}
                          onChange={onHandleChange}
                          name="fullName"
                          placeholder="Full Name"
                        />
                        <label for="reg_firstname">Full Name</label>
                      </div>
                    </div>
                    {/* <div className="col-md-12"> 
                      <div className="form-floating">
                        <input
                          type="email"
                          className="form-control"
                          id="reg_email"
                          name="email"
                          value={register?.email}
                          onChange={onHandleChange}
                          placeholder="Email"
                        />
                        <label for="reg-email">Email</label>
                      </div>
                    </div> */}

                    <div className="col-md-12 mt-3"  >
                      <div className="form-floating d-flex">

                        <div style={{ width:"200px" , borderBottom:"1px solid grey" }}>
                          <select id="" class="form-control">
                            <option >country code</option>
                            <option>+965</option>
                            <option>+974</option>
                            <option>+966</option>
                            <option>+971</option>
                          </select>
                        </div>

                       <div className="col-md-9" >
                        <input
                          // style={{border:"1px solid red"}}
                            type="number"
                            className="form-control  "
                            id="reg_mobile"
                            name="phone"
                            value={register?.phone}
                            onChange={onHandleChange}
                            placeholder="Phone Number"
                          />
                        {/* <label for="reg_mobile">Phone Number</label> */}
                       </div>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="reg_password"
                          name="password"
                          value={register?.password}
                          onChange={onHandleChange}
                          placeholder="Password"
                        />
                        <label for="reg-password">Password</label>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-floating">
                        <input
                          type="password"
                          className="form-control"
                          id="reg_repasswordd"
                          name="confirmPassword"
                          value={register?.confirmPassword}
                          onChange={onHandleChange}
                          placeholder="Confrim Passoword"
                        />
                        <label for="reg-repassword">Confirm Password</label>
                      </div>
                    </div>
                  </form>
                  <br />
                  {/* <div className="form-group reg-terms">
                    <ul className="unstyled checkbox-input">
                      <li>
                        <input
                          className="styled-checkbox"
                          id="terms"
                          type="checkbox"
                          value=""
                        />
                        <label for="terms">
                          Accept{" "}
                          <Link
                            // href="javascript:void(0);"
                            data-src="pdf/terms.pdf"
                            className="fancyIframe"
                          >
                            Terms and Condition
                          </Link>
                        </label>
                      </li>
                    </ul>
                    <br />
                  </div> */}
                  <br />
                  <div className="signup-btn" style={{width:"100%"}}>
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="button-submit hover-target"
                      style={{width:"100%"}}
                    >
                      Submit
                    </button>
                  </div>
                  <br />
                  {/* <span className="or-login">OR</span> */}
                  {/* <div className="login-with">
                    Sign In With
                    <a href="/login" className="login-link">
                      <img
                        src="images/google-plus.jpg"
                        className="img-fluid"
                        alt=""
                      />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
