/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch, useSelector } from "react-redux";
import { getUserDetial } from "../redux/MainAction";
import React, { useEffect, useState } from "react";
import {setNewPassword , contactEnquiry , editProfile ,getTERMS ,getREFUNDPOLICY , getPRIVACYPOLICY , CancelSubscription} from "../redux/MainAction"

import swal from 'sweetalert';

const MyAccount = () => {
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';
  const [toggle , setToggle] = useState()

  const dispatch = useDispatch()
  const User = useSelector(state=>state?.HitFitSite?.userDetails)
  const TERMS = useSelector(state=>state?.HitFitSite?.terms)
  const REFUND = useSelector(state=>state?.HitFitSite?.refund)
  const POLICY = useSelector(state=>state?.HitFitSite?.privacy)

  const [ clasBtn , setClasBtn] = useState('editBtn')
  const [ head , setHead ] = useState()

  const [ passData , setPassData ] = useState({
    oldPassword:"",
    newPassword:"",
    cnewPassword:""
  })

  const [ contactData , setContactData ] = useState({
    name:"",
    email:'',
    phone:null,
    message:"",
    address:'',
  })


  const [ profile , setProfile ] = useState({
    userName:"",
    fullName:"",
    email:"",
    phone:null,
    gender:"male",
  })

  useEffect(()=>{
    if(User){
      setProfile({
        userName: User?.userName,
        fullName:User?.fullName,
        email:User?.email,
        phone:(User?.phone),
        gender:User?.gender,
      })
      setContactData({
        name:User?.fullName,
        email:User?.email,
        phone:(User?.phone),
        message:"",
      })
    }
  },[User])

  const onProfile =(e)=>{
    const {name, value} =e.target
    setProfile({...profile,[name]:value})
  }

  const  onSaveProfile = (e) =>{
    e.preventDefault()
    var data = {
      userName:profile?.userName,
      fullName:profile?.fullName,
      email:profile?.email,
      phone:profile?.phone,
      gender:profile?.gender,
      roleId:3
    }
    console.log("PROFILE" , data);
    dispatch(editProfile(data))
  }

  const onChangePass =(e)=>{
    const {name, value} =e.target
    setPassData({...passData,[name]:value})
  }

  const handlPass =(e)=>{
    e.preventDefault()
    if(passData?.cnewPassword !== passData?.newPassword){
      swal({
        title: 'Warning!',
        text: "Your New Password & Confirm New Password not Match !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else{
      var data ={
        oldPassword:passData?.oldPassword,
        newPassword:passData?.newPassword,
        // cnewPassword:passData?.cnewPassword,
      }
      dispatch(setNewPassword(data))
      console.log("DTA" , data);
    }
  }

  const handleContact =(e)=>{
    e.preventDefault()
    if(contactData.name===""){
      swal({
        title: 'Warning!',
        text: "Please Add Your Full Name !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if( contactData.email===""){
      swal({
        title: 'Warning!',
        text: "Please Add Your Email !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if( contactData?.message===""){
      swal({
        title: 'Warning!',
        text: "Please Add Your Message !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }else if( contactData.phone===""){
      swal({
        title: 'Warning!',
        text: "Please Add Phone No !",
        icon: "warning",
        button: "Ok",
      });
      return false
    }
    var data ={
      name:contactData?.name,
      email:contactData?.email,
      message:contactData?.message,
      phone:contactData?.phone,
      // address:contactData?.address,
    }
    console.log("Contact" , data);
    dispatch(contactEnquiry(data))
  }

  const onContactHandle = (e)=>{
    const {name, value} =e.target
    setContactData({...contactData,[name]:value})
  }


  console.log("URl",window.location.href);


  useEffect(()=> {
    getList()
  },[]) 
  const getList = () => {
      var data = {
        // includePackages:true,
      }
      dispatch(getUserDetial(data))
      dispatch(getTERMS())
      dispatch(getREFUNDPOLICY())
      dispatch(getPRIVACYPOLICY())

  }

  console.log("USER", User);
  const handleToggle = (e) =>{
    if(e?.target?.id==="edit"){
        setToggle("block")
    }else {
      setToggle("none")
    }
  }
  const  handleLogOut = ()=> {
    localStorage.clear()
    window.location.href='/'
  }
  const  handleCancelSub = ()=> {
    var sbs = User?.UserSubscriptions[User.UserSubscriptions.length - 1]
    var data = {
      userSubscriptionId: parseInt(sbs.id)
    }
    // console.log("data",data);
    dispatch(CancelSubscription(data))
  }


  const handleClass = (e)=> {
    console.log("VA", e.target.id);
    setClasBtn(e.target.id)
  }
  useEffect(()=>{
    if(clasBtn==="editBtn"){
      setHead("Edit Your Profile")
    }else if (clasBtn==="pushN"){
      setHead("Push Notification")
    }else if(clasBtn==="changePass"){
        setHead("Change Password")
    }
    else if(clasBtn==="T&C"){
        setHead("Terms & Condition")
    }
    else if(clasBtn==="RP"){
        setHead("Refund Policy")
    }
    else if(clasBtn==="contactUs"){
        setHead("Contact Us")
    }
    else if(clasBtn==="privacyP"){
        setHead("Privacy Policy")
    }
  },[clasBtn])

  // var AuthToken = localStorage.getItem("AuthToken")
  // console.log("AuthToken",AuthToke);
  const getUrl = window.location.href

  useEffect(()=>{
    if (getUrl.includes('tnc')) {
      setClasBtn("T&C")
    } else if(getUrl.includes('privacy-policy')) {
      setClasBtn("privacyP")
    }
    else if(getUrl.includes('contact_us')) {
      setClasBtn("contactUs")
    }
  },[getUrl])
  return (
    <>
      <section className="inner-banner-main">
        <img src="images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>
      
      <section className="innerpage-section">
        <div className="padding-left-right">
          <div className="container">
            <div className="diet-head">
              <h2>{head}</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-5 col-md-5 dashboard dashboard-main wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <a href="javascript:void(0);" className="dashboard-link">
                  <span></span>
                </a>
                {localStorage.getItem('web')? <h3>My Account</h3> :''}
                <ul className="profile-link">
                  {localStorage.getItem('web')? 
                  <li className="anim1">
                    <a href="javascript:void(0)" id="editBtn" onClick={handleClass} className={clasBtn==="editBtn"?"active":""}>
                      <figure>
                        <img src="images/account/edit.svg" alt="My Bids" />
                      </figure>
                      {lang==='en'?"Edit Profile" :" تعديل الملف الشخصي"}<span></span>
                    </a>
                  </li>
                  :''}
                  { localStorage.getItem('web')?
                  <li className="anim1">
                    <a href="/subscription-history">
                      <figure>
                        <img
                          src="/images/account/subscription.svg"
                          alt="Edit profile"
                        />
                      </figure>
                      {lang==='en'? "Subscription History" :"سجل الاشتراكات"}<span></span>
                    </a>
                  </li>
                  :""}
                  
                  {localStorage.getItem('web')? 
                  <li className="anim4">
                    <a href="javascript:void(0)" id="changePass" onClick={handleClass} className={clasBtn==="changePass"?"active":""} >
                      <figure>
                        <img
                          src="images/account/change-password.svg"
                          alt="My Ads"
                        />
                      </figure>
                      {lang==='en'? "Change Password" :"تغير كلمة المرور"}<span></span>
                    </a>
                  </li>
                  :''}
                  <li className="anim5">
                    <a href="javascript:void(0)" id="T&C"  onClick={handleClass} className={clasBtn==="T&C"?"active":""} >
                      <figure>
                        <img
                          src="/images/account/terms-conditions.svg"
                          alt="Favorites"
                        />
                      </figure>
                      {lang==='en'? "Terms & Condition":"الشروط و الاحكام"}<span></span>
                    </a>
                  </li>
                  {localStorage.getItem('web')?
                    <li className="anim6">
                    <a href="javascript:void(0)" id="RP" onClick={handleClass} className={clasBtn==="RP"?"active":""}>
                      <figure>
                        <img
                          src="images/account/refund.svg"
                          alt="Recently Viewed"
                        />
                      </figure>
                      {lang==='en'? "Refund Policy" :"سياسة الاسترداد"}<span></span>
                    </a>
                  </li>
                  :''}
                  <li className="anim7">
                    <a href="javascript:void(0)" id="privacyP" onClick={handleClass} className={clasBtn==="privacyP"?"active":""} >
                      <figure>
                        <img
                          src="images/account/privacy.svg"
                          alt="Change Password"
                        />
                      </figure>
                      {lang==='en'? "Privacy Policy" :"سياسة الخصوصية"}<span></span>
                    </a>
                  </li>
                  <li className="anim8">
                    <a href="javascript:void(0)" id="contactUs" onClick={handleClass} className={clasBtn==="contactUs"?"active":""} >
                      <figure>
                        <img src="images/account/contact.svg" alt="Logout" />
                      </figure>
                      {lang==='en'? "Contact Us" :"اتصل بنا"}<span></span>
                    </a>
                  </li>
                  { localStorage.getItem('web')?
                  <li className="anim1">
                    <a href="javascript:void(0);" onClick={handleCancelSub}>
                      <figure>
                        <img
                          src="/images/account/subscription.svg"
                          alt="Edit profile"
                        />
                      </figure>
                      {lang==='en'? "Cancel Subscription" :"إلغاء الاشتراك "}<span></span>
                    </a>
                  </li>
                  :""}
                  {localStorage.getItem('web')? 
                  <li className="anim9">
                    <a  href="javascript:void(0);" onClick={handleLogOut}>
                      <figure>
                        <img src="images/account/logout.svg" alt="Logout" />
                      </figure>
                      {lang==='en'? "Logout" : "تسجيل الخروج"}<span></span>
                    </a>
                  </li>
                  :''}
                </ul>
              </div>
             {clasBtn==="editBtn"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <h3>My Profile</h3>
                <div className="dashboard-main">
                  <div className="dashboard-one" style={{display:toggle==="block"?"none":"block"}} >
                    <div className="form-group photo-name">
                      <div className="single uploader profile-photo">
                        <div className="thumb-holder">
                          <div className="profile-thumb">
                            <img src={User?.imageUrl} alt="Arfi" />
                          </div>
                          <div className="fileContainer"></div>
                          <div className="plus-sign">
                            <input
                              name="files[]"
                              type="file"
                              maxlength="1"
                              multiple
                              className="multi with-preview"
                              accept="jpg|gif|png"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="profile-name-email">
                        <h3>{User?.fullName}</h3>
                        <p>
                          <a href="mailto:arfi.ganteng@gmail.com">
                            {User?.email}
                          </a>
                        </p>
                        <p>{User?.phone}</p>
                      </div>
                      <div className="edit-div">
                        <a href="javasript:;" className="button edit-btn" id="edit" onClick={(e)=>handleToggle(e)}>
                          {" "}
                          {lang==='en'? "Edit" :" تعديل"}{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="dashboard-two" style={{display:`${toggle}`}}>
                    <div className="form-group">
                      <div className="double">
                        <div className="lbl">Full Name</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="fullName"
                            id=""
                            placeholder="Full Name..."
                            value={profile?.fullName}
                            onChange={onProfile}
                            type="text"

                          />
                        </div>
                      </div>
                      <div className="double">
                        <div className="lbl">User Name</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="userName"
                            id="userName"
                            type="text"
                            placeholder="User Name..."
                            value={profile?.userName}
                            onChange={onProfile}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="double">
                        <div className="lbl">Email Address</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="email"
                            type="email"
                            id="email"
                            placeholder="Email Address..."
                            value={profile?.email}
                            onChange={onProfile}
                          />
                        </div>
                      </div>
                      <div className="double">
                        <div className="lbl">Mobile Number</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="phone"
                            id="phone"
                            type="number"
                            placeholder="Mobile Number..."
                            value={profile?.phone}
                            onChange={onProfile}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="double">
                        <div className="lbl">Gender</div>
                        <div className="selectBox">
                          <select name="gender" value={profile?.gender}  onChange={onProfile}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group text-center">
                      <div className="inputbox">
                        <button type="submit" className="button" onClick={onSaveProfile}>Update</button>{" "}
                        <a href="javasript:;" className="button close-btn" id="close" onClick={(e)=>handleToggle(e)}>
                          Close
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
             {clasBtn==="changePass"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <h3>Change Your Password</h3>
                <div className="dashboard-main">
                  <div className="dashboard-two" style={{display:"block"}}>
                    <div className="form-group">
                      <div className="double">
                        <div className="lbl">Old Password</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="oldPassword"
                            type="password"
                            id="oldPassword"
                            placeholder="Old Password..."
                            onChange={onChangePass}
                            value={passData?.oldPassword}
                          />
                        </div>
                      </div>
                      <div className="double">
                        <div className="lbl">New Password</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="newPassword"
                            id="newPassword"
                            type="password"
                            placeholder="New Password..."
                            onChange={onChangePass}
                            value={passData?.newPassword}
                          />
                        </div>
                      </div>
                      <div className="double" >
                        <div className="lbl">Confirm New Password</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="cnewPassword"
                            id="cnewPassword"
                            placeholder="Confirm New Password..."
                            type="password"
                            onChange={onChangePass}
                            value={passData?.cnewPassword}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group"  >
                      
                    </div>
                    <div className="form-group text-center">
                      <div className="inputbox">
                        <button className="button" type="submit" onClick={handlPass}>Update</button>
                        {/* <a href="javasript:;" className="button close-btn" >
                          Close
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
             {clasBtn==="contactUs"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub wow fadeInUp"
                data-wow-delay="0.6s"
              >
                <h3>Contact Us</h3>
                <div className="dashboard-main">
                  <div className="dashboard-two" style={{display:"block"}}>
                    <div className="form-group">
                      <div className="double">
                        <div className="lbl">Full Name</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="name"
                            type="text"
                            id="name"
                            placeholder="Full Name..."
                            onChange={onContactHandle}
                            value={contactData?.name}
                          />
                        </div>
                      </div>
                      <div className="double">
                        <div className="lbl">Email</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="email"
                            id="email"
                            type="email"
                            placeholder="Email..."
                            onChange={onContactHandle}
                            value={contactData?.email}
                          />
                        </div>
                      </div>
                      <div className="double" >
                        <div className="lbl">Mobile No</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="phone"
                            id="phone"
                            type="number"
                            placeholder="Mobile No..."
                            onChange={onContactHandle}
                            value={contactData?.phone}
                          />
                        </div>
                      </div>
                      <div className="double">
                        <div className="lbl">Message</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="message"
                            id="message"
                            type="text"
                            placeholder="Message..."
                            onChange={onContactHandle}
                            value={contactData?.message}
                          />
                        </div>
                      </div>
                      {/* <div className="double">
                        <div className="lbl">Address</div>
                        <div className="inputbox">
                          <input
                            className="form-control"
                            name="address"
                            id="address"
                            type="text"
                            placeholder="Address..."
                            onChange={onContactHandle}
                            value={contactData?.address}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="form-group"  >
                      
                    </div>
                    <div className="form-group text-center">
                      <div className="inputbox">
                        <button type="submit" onClick={handleContact} className="button">Send</button>{" "}
                        {/* <a href="javasript:;" className="button close-btn" >
                          Close
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
             { clasBtn==="T&C"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub policy "
                data-wow-delay="0.6s" 
              >
                <h3>Terms And Condition</h3>
                <div className="dashboard-main">
                  <div className="dashboard-two" style={{display:"block"}}>
                    <div className="form-group" >
                      <div
                           dangerouslySetInnerHTML={{__html:TERMS[0]?.termsAndConditions?.termsAndConditionsEn }}
                      />
                      {}
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
             { clasBtn==="RP"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub policy "
                data-wow-delay="0.6s" 
              >
                <h3>Refund Policy</h3>
                <div className="dashboard-main">
                  <div className="dashboard-two" style={{display:"block"}}>
                    <div className="form-group" >
                      <div
                           dangerouslySetInnerHTML={{__html:REFUND?.refundPolicies?.refundPoliciesEn}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
             { clasBtn==="privacyP"?
              <div
                className="col-lg-7 col-md-7 col-sm-12 dashboard-sub policy "
                data-wow-delay="0.6s" 
              >
                <h3>Privacy Policy</h3>
                <div className="dashboard-main">
                  <div className="dashboard-two" style={{display:"block"}}>
                    <div className="form-group" >
                      <div
                           dangerouslySetInnerHTML={{__html:POLICY?.privacyPolicies?.privacyPolicyEn}}
                      />
                    </div>
                  </div>
                </div>
              </div>
              :""
             }
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MyAccount;
