/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import {getBlog} from '../redux/MainAction'
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BlogDetails = () => {
  const param = useParams()
  const dispatch = useDispatch();
  const Blogs = useSelector((state) => state?.HitFitSite?.blog);
  const [ Data , setData] = useState([])

  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';

  useEffect(() => {
    getBlogs()
  },[]);

  const getBlogs =()=>{
    var data ={
       "isActive":true
    }
    dispatch(getBlog(data))
  }

  useEffect(()=>{
    if(param?.id){
        var fil = Blogs?.filter(itm=>itm?.id==param?.id)
        // console.log("fil" , fil);
        setData(fil?fil[0]:null)
    }
  },[param,Blogs])

  console.log("Data", Data);
  console.log("Video", Data?.video?.url ? true :false );

  return (
    <>
      <section className="innerpage-section blogs">
        <div className="padding-left-right">
          <div className="container">
            <h2 className="diet-plan-head">{Data?.title}</h2>
            <div
                className="col-lg-7 col-md-7 col-lg-12 dashboard-sub "
                data-wow-delay="0.6s" 
              >
                <div className="dashboard-main">
                  <div className="datCard">
                  {  Data?.video?.url ?
                    <video
                      src={Data?.video?.url}
                      className="workout-video"
                      autoPlay ="true"
                      id=""
                      controls
                      muted=""
                      loop=""
                      playsInline
                    >
                      <source  type="video/mp4" />
                    </video>

                    :
                    <img src={Data?.image?.url}  />
                  }
                    <div className="hittfitt-video">
                   
                </div>
                  </div> 
                  <h2 className="diet-plan-head"></h2>
                  <>
                    <div className="blogText" style={{ direction: lang !== "en" ? "rtl" : "" }}
                      dangerouslySetInnerHTML={{ __html: Data?.description }}
                    />
                  </>
                </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetails;
