import {
  GET_HOME_PAGE,
  GET_LIVE_SESSION,
  GET_NUTRITION_DIET_PLAN,
  GET_PACKAGE,
  GET_PRIVACY_POLICY,
  GET_PROGRAM,
  GET_REFUNDT_POLICY,
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_HISTORY,
  GET_TERMS_CONDITION,
  GET_USER_DETAILS,
  GET_YOUR_PLAN,
  JOIN_PROGRAM,
  LOGIN_DETAILS,
  SIGN_UP_DETAILS,
  SIGN_UP_WITH_EMAIL,
  GET_ADS,
  GET_BLOG,
  GET_WEB_IMAGE,
  GET_CATEGORY,
  GET_NUTRITION_MEAL
} from "./types";

const initialState = {
  signupData: {},
  signupwithEmail: {},
  LoginData: {},
  homeDetails: [],
  package: [],
  program: [],
  nutritionDiet: [],
  liveSession: [],
  yourPlan: [],
  joinProgram: [],
  userDetails: [],
  subscriptionHistory: [],
  terms: [],
  refund: [],
  privacy: [],
  subscrptionList: [],
  ads:[],
  blog:[],
  webImg:[],
  category:[],
  NutritionMeal:[],
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_DETAILS:
      return {
        ...state,
        signupData: action.payload,
      };
    case SIGN_UP_WITH_EMAIL:
      return {
        ...state,
        signupwithEmail: action.payload,
      };
    case LOGIN_DETAILS:
      return {
        ...state,
        LoginData: action.payload,
      };
    case GET_HOME_PAGE:
      return {
        ...state,
        homeDetails: action.payload,
      };
    case GET_PACKAGE:
      return {
        ...state,
        package: action.payload,
      };
    case GET_PROGRAM:
      return {
        ...state,
        program: action.payload,
      };
    case GET_NUTRITION_DIET_PLAN:
      return {
        ...state,
        nutritionDiet: action.payload,
      };
    case GET_LIVE_SESSION:
      return {
        ...state,
        liveSession: action.payload,
      };
    case GET_YOUR_PLAN:
      return {
        ...state,
        yourPlan: action.payload,
      };
    case JOIN_PROGRAM:
      return {
        ...state,
        joinProgram: action.payload,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case GET_SUBSCRIPTION_HISTORY:
      return {
        ...state,
        subscriptionHistory: action.payload,
      };
    case GET_TERMS_CONDITION:
      return {
        ...state,
        terms: action.payload,
      };
    case GET_REFUNDT_POLICY:
      return {
        ...state,
        refund: action.payload,
      };
    case GET_PRIVACY_POLICY:
      return {
        ...state,
        privacy: action.payload,
      };
    case GET_SUBSCRIPTION:
      return {
        ...state,
        subscrptionList: action.payload,
      };
    case GET_ADS:
      return {
        ...state,
        ads: action.payload,
      };
    case GET_BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case GET_WEB_IMAGE:
      return {
        ...state,
        webImg: action.payload,
      };
    case GET_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    case GET_NUTRITION_MEAL:
        return {
          ...state,
          NutritionMeal:action.payload
        }

    default:
      return state;
  }
};

export default mainReducer;
