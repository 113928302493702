import './App.css';
import Home from './components/Home';
import Footer from './components/common/Footer';
import Header from './components/common/Header';
import { Route,BrowserRouter as Router, Routes } from 'react-router-dom'
import Login from './components/common/Login';
import Signup from './components/common/Signup';
import WorkoutsProgams from './components/WorkoutsProgams';
import NutritionCenter from './components/NutritionCenter';
import LiveSession from './components/LiveSession';
import YourPlan from './components/YourPlan';
import JoinLiveSession from './components/JoinLiveSession';
import WorkoutListing from './components/WorkoutListing';
import VeganDiet from './components/VeganDiet';
import SubscriptionWithUs from './components/SubscriptionWithUs';
import SubscriptionHistory from './components/SubscriptionHistory';
import MyAccount from './components/MyAccount';
import WorkoutPlan from './components/WorkoutPlan';
import YourPlanList from './components/YourPlanList';
import SignupWithEmail from './components/common/SignupWithEmail';
import Blog from './components/Blog';

import './Assets/GE_dinar_one_medium.ttf';
import './Assets/fontstyle.css';
import BlogDetails from './components/BlogDetails';
import ViewPdf from './components/ViewPdf';
import Testing from './components/Testing';

function App() {
  var AuthToken = localStorage.getItem("AuthToken")
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';
  console.log("langnew", lang);
  
  return (
    <div className="App" style={{fontFamily: lang === "en" ? "'Assistant', sans-serif" :  "'arabicFont', sans-serif"}}>
      <Header/>
        {AuthToken ? 
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/testing' exact element={<Testing/>} />
          <Route path='/login' exact element={<Login/>}/>
          <Route path='/sign-up' exact element={<SignupWithEmail/>}/>
          <Route path='/continue-with' exact element={<Signup/>}/>
          <Route path='/workout-program' exact element={<WorkoutsProgams/>}/>
          <Route path='/workouts-listings/:pack_id?' exact element={<WorkoutListing/>}/>
          <Route path='/workouts-plan/:prog_id?' exact element={<WorkoutPlan/>}/>
          <Route path='/nutirtion-center' exact element={<NutritionCenter/>}/>
          <Route path='/nutirtion-center/:id' exact element={<ViewPdf/>}/>
          <Route path='/vegan-diet/:id?' exact element={<VeganDiet/>}/>
          <Route path='/live-session' exact element={<LiveSession/>}/>
          <Route path='/join-live-session' exact element={<JoinLiveSession/>}/>
          <Route path='/subscribe-with-us' exact element={<SubscriptionWithUs/>}/>
          <Route path='/subscription-history' exact element={<SubscriptionHistory/>}/>
          <Route path='/my-plan' exact element={<YourPlan/>}/>
          <Route path='/my-plan/:prog_id?' exact element={<YourPlanList/>}/>
          <Route path='/my-account' exact element={<MyAccount/>}/>
          <Route path='/tnc' exact element={<MyAccount/>}/>
          <Route path='/privacy-policy' exact element={<MyAccount/>}/>
          <Route path='/contact_us' exact element={<MyAccount/>}/>
          <Route path='/blog' exact element={<Blog/>}/>
          <Route path='/blog/details/:id' exact element={<BlogDetails/>}/>
        </Routes>
        : 
        <Routes>
          <Route path='/' exact element={<Home/>} />
          <Route path='/login' exact element={<Login/>}/>
          <Route path='/sign-up' exact element={<SignupWithEmail/>}/>
          <Route path='/continue-with' exact element={<Signup/>}/>
          <Route path='/workout-program' exact element={<WorkoutsProgams/>}/>
          <Route path='/workouts-listings/:pack_id?' exact element={<WorkoutListing/>}/>
          <Route path='/workouts-plan/:prog_id?' exact element={<WorkoutPlan/>}/>
          <Route path='/nutirtion-center' exact element={<NutritionCenter/>}/>
          <Route path='/vegan-diet' exact element={<VeganDiet/>}/>
          <Route path='/live-session' exact element={<LiveSession/>}/>
          <Route path='/join-live-session' exact element={<JoinLiveSession/>}/>
          <Route path='/subscribe-with-us' exact element={<SubscriptionWithUs/>}/>
          <Route path='/subscription-history' exact element={<SubscriptionHistory/>}/>
          <Route path='/blog' exact element={<Blog/>}/>
          <Route path='/blog/details/:id' exact element={<BlogDetails/>}/>
          <Route path='/tnc' exact element={<MyAccount/>}/>
          <Route path='/privacy-policy' exact element={<MyAccount/>}/>
          <Route path='/contact_us' exact element={<MyAccount/>}/>
        </Routes>}
      <Footer/>
    </div>
  );
}

export default App;
