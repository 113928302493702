import React, { useEffect, useState } from "react";
import { getNutrtionDietPlan , getCategory } from "../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const VeganDiet = () => {
  const params = useParams()
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';

  const [ details , setDetails ] = useState([])

  var log = "true"
  const dispatch = useDispatch()
  const NutritionPlan = useSelector(state=>state?.HitFitSite?.nutritionDiet)
  useEffect(()=> {
    getList()
  },[]) 
 
  const getList = () => {
    if(log){
      var data ={
        isActive: true
      }
      dispatch(getNutrtionDietPlan(data))
      // dispatch(getCategory(data))
    }
  }
  useEffect(()=>{
    if(NutritionPlan){
      var filter = NutritionPlan?.filter(itm=>itm?.id==params?.id)
      setDetails(filter)
    }
  } ,[NutritionPlan])
  console.log("N", details[0]?.dietDetails?.data);
  return (
    <>
      <section class="inner-banner-main">
        <img src="images/inner-page-banner.jpg" class="img-fluid" alt="" />
      </section>

      <section class="innerpage-section">
        <div class="padding-left-right">
          <div class="container">
            <h2>Vegan Diet</h2>
            <div class="diet-text-head">
              <h4>System 25:</h4>
              <p>GET FIT Flexible for Ramadan</p>
            </div>

            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="diet-text-desc">
                  <h4>Vegan Diet</h4>
                  {details[0]?.dietDetails?.data?.map((itm)=>{
                    var s =  itm?.description
                    var r  = s.replace(/\b\d+\.\s+(.+?)\s*(?=\b\d+\n. |\s*$)/g, "<li> $1 </li>\n") ;
                    var result = s.split(/(^\d+\.+\s+|\s\d\.\s)/);
                    return <ol>{r}</ol>
                  })}
                  {/* <ol class="listings-diet-text">
                    <li>
                      Drink at least 2 liters of water daily.
                      <li>Drink 2 glasses of water before eating.</li>
                      <li>Sleep 6-7 hours.</li>
                      <li>You can divide your breakfast into two parts.</li>
                      <li>
                        Commit to HIIT25 exercises half an hour before
                        breakfast.
                      </li>
                      <li>
                        It is preferable to add iron exercise and preferably
                        after breakfast an hour and a half for two hours or
                        walking.
                      </li>
                    </li>
                  </ol> */}
                  <h5>
                    <img src="images/walk.png" class="img-fluid" alt="" />
                    10,000 - 12,000 steps per day.
                  </h5>
                </div>
              </div>
              {/* <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="diet-text-desc">
                  <h4>Vegan Diet</h4>
                  <ol class="listings-diet-text">
                    <li>
                      Drink at least 2 liters of water daily.
                      <li>Drink 2 glasses of water before eating.</li>
                      <li>Sleep 6-7 hours.</li>
                      <li>You can divide your breakfast into two parts.</li>
                      <li>
                        Commit to HIIT25 exercises half an hour before
                        breakfast.
                      </li>
                      <li>
                        It is preferable to add iron exercise and preferably
                        after breakfast an hour and a half for two hours or
                        walking.
                      </li>
                    </li>
                  </ol>
                  <h5>
                    <img src="images/walk.png" class="img-fluid" alt="" />
                    10,000 - 12,000 steps per day.
                  </h5>
                </div>
              </div> */}
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
};

export default VeganDiet;
