/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { getNutrtionDietPlan , getCategory ,getNutritionMeal } from "../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";
import { pdfjs } from "react-pdf";
import PdfComp from "./PdfComp";
import { useNavigate } from "react-router-dom";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

const NutritionCenter = () => {
  const navigate = useNavigate()
  var lang = localStorage.getItem("lang") !== null ? localStorage.getItem("lang") : 'en';
  var AuthToken = localStorage.getItem("AuthToken")

  var log = "true"
  const dispatch = useDispatch()
  const NutritionPlan = useSelector(state=>state?.HitFitSite?.nutritionDiet)
  const Meal = useSelector(state=>state?.HitFitSite?.NutritionMeal)
  const Category = useSelector(state=>state?.HitFitSite?.category)

  const [ nutrition , setNutrition ] = useState([])
  const [ popData , setPop] = useState([])
  const [ toggle , setToggle] = useState(false)
  const [ pdf , setPDF ] = useState('')
  console.log("Pop", popData);

  useEffect(()=> {
    getList()
    getMeal()
  },[]) 
 
  const getList = () => {
    if(log){
      var data ={
        isActive: true
      }
      dispatch(getNutrtionDietPlan(data))
      dispatch(getCategory(data))
    }
  }

  const getMeal = () =>{
    var dat1 = {
      "limit":1000,
    }
    dispatch(getNutritionMeal(dat1))
  } 
  useEffect(()=>{
    if(NutritionPlan){
      setNutrition(NutritionPlan)
    }
  } ,[NutritionPlan])

  // console.log("NTR", nutrition);
  const handlePopData = (nut)=> {
    setPop(nut)
  }
  const handleCate = (id) =>{
    var filter = NutritionPlan?.filter(itm=>itm?.categoryId==id)
    console.log("CAT",filter);
    setToggle(true)
    setNutrition(filter)

  }
  const handleAll = ()=>{
    setNutrition(NutritionPlan)
  }
  const handleID =(id)=> {
    // console.log("ID",id);
    // var mealFilter = Meal?.filter(itm=>itm?.dietPlanId==id)
    // console.log("ID", mealFilter);
    setPDF(id)
  }

  const onViewMeal=()=>{
    if(AuthToken){
      // navigate('/nutirtion-center/'+ pdf)
      window.location.href='/nutirtion-center/'+ pdf
    }else{
      window.location.href='/login'
      // navigate('/login')
    }
  }
  console.log("pdf", nutrition );
  console.log("meal", Meal );
  return (
    <>
      <section className="inner-banner-main">
        <img src="/images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>

      <section className="innerpage-section">
        <div className="padding-left-right">
          <div className="container">
            <h2 className="diet-plan-head">{lang==="en"? "Nutrition Center" : "مركز التغذية"}</h2>
            <ul
              className="nav nav-pills mb-3 nav-pills-diet justify-content-center"
              id="pills-tab"
              role="tablist"
            >
             <li className="nav-item" role="presentation">
              <button
                    className={!toggle?"nav-link nav-link-diet  btn" : "nav-link nav-link-diet"}
                    id="pills-loose-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-loose"
                    type="button"
                    role="tab"
                    aria-controls="pills-loose"
                    aria-selected="false"
                    onClick={handleAll}
                  >
                    {"All"}
                  </button>
             </li>
              { Category?.map((Itm)=>(
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link nav-link-diet "
                    id="pills-loose-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-loose"
                    type="button"
                    role="tab"
                    aria-controls="pills-loose"
                    aria-selected="false"
                    tabindex="-1"
                    onClick={()=>handleCate(Itm?.id)}
                  >
                    {Itm?.title}
                  </button>
                </li>
              ))
              }
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-loose"
                role="tabpanel"
                aria-labelledby="pills-loose-tab"
              >
                <div className="row g-2">
                  { nutrition == "" ?
                  <span><h2 className="diet-plan-head">No Diets Here...</h2></span>
                  : 
                  nutrition?.map((nut)=>(

                  <div className="col-lg-6 col-md-12" onClick={()=>handleID(nut?.id)}>
                    <a
                      href="javascript:void(0)"
                      data-fancybox=""
                      data-src={true?"#vegan" : ''}
                      onClick={()=>handlePopData(nut)}
                    >
                      <div className="row diet-plan-col">
                        <div className="col-lg-4 col-md-4 col-sm-12">
                          <div className="diet-plan-listings">
                            <img
                              src={nut?.Image?.url}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12">
                          <div className="diet-plan-listings">
                            <h3>{nut?.title}</h3>
                            <p>
                              {nut?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="vegan" className="popup-hidden animated-modal">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="plan-details-img">
                <img
                  src={popData?.Image?.url}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              { popData?.dietDetails?.data?.map((diet)=>(
                <div className="plan-details-desc">
                  <h3>{diet?.title}</h3>
                  <p>
                   {diet?.description}
                  </p>
                 <hr />
              </div>
              ))
              }
            </div>
          </div>

          <div className="view-meals-btn">
            <a onClick={onViewMeal} href="javascript:void(0)">{pdf?"View Meals": "No Meals"}</a>
          </div>
          {/* <PdfComp pdfFile={pdf}/> */}
        </div>
      </div>
    </>
  );
};

export default NutritionCenter;
