import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onPackage, onPackageProgram } from "../redux/MainAction";

const WorkoutsProgams = () => {
  var log = "true"
  const dispatch = useDispatch()
  const PackageList = useSelector(state=>state?.HitFitSite?.package)
  const ProgramList = useSelector(state=>state?.HitFitSite?.program)

  const [ PACKAGE , setPACKAGE ] = useState([])

  useEffect(()=> {
    getList()
  },[]) 

  const getList = () => {
    if(log){
      dispatch(onPackage())
      var data = {
        includePackages:true,
        isActive:true
      }
      dispatch(onPackageProgram(data))
    }
  }
  useEffect(()=>{
    if(PackageList){
      setPACKAGE(PackageList)
    }
  } ,[PackageList])

  console.log("consoleLogData" , ProgramList, PackageList, PACKAGE);
  return (
    <>
      <section className="inner-banner-main">
        <img src="/images/inner-page-banner.jpg" className="img-fluid" alt="" />
      </section>

      <section className="innerpage-section">
        <div className="padding-left-right">
          <h2>Workout Programs</h2>
          { PACKAGE?.map((pack)=>(

            <div className="container">
            <div className="workout-div mt-5">
              <h3>{pack?.title}</h3>
              <h6>
                <a href={`/workouts-listings/` + pack.id}>See All</a>
              </h6>
            </div>
            <div className="row g-2">

              {ProgramList?.filter((prg , idx)=> prg.Packages.map((pkg)=>pkg.id).includes(pack?.id)).slice(0,3)?.map((program)=>(
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <div className="workout-col">
                  <a href={`/workouts-plan/` + program?.id}>

                    <img
                      src={program?.MainImage?.url}
                      className="img-fluid"
                      alt=""
                      // style={{height:"300px", width:"500px", opacity:'0.9'}}
                    />
                    <div className="workout-left">
                      <h4>{program?.title}</h4>
                      <span className="days">{program?.totalDuration}</span>
                    </div>
                    <div className="workout-right">
                      <h4 >{program?.level}</h4>
                      <span className="days tw-bold">{program?.perDayDuration}</span>
                    </div>
                  </a>
                  </div>
                </div>
              ))

              }
              {/* <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="workout-col">
                  <img
                    src="images/workouts/workout-3.png"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="workout-left">
                    <h4>Basic HIIT</h4>
                    <span className="days">30 Days</span>
                  </div>
                  <div className="workout-right">
                    <h4>Beginner</h4>
                    <span className="days">20 Min/Day</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          ))
          }
          <div className="workout-space"></div>
          {/* <div className="container">
            <div className="workout-div">
              <h3>Dumbbells</h3>
              <h6>
                <a href="/workouts-listings">See All</a>
              </h6>
            </div>
            <div className="row g-2">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="workout-col workout-col-2">
                  <img
                    src="images/workouts/dumbbell-1.png"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="workout-left">
                    <h4>Lower Body</h4>
                  </div>
                  <div className="workout-right workout-dumbbell">
                    <h4>All Levels</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="workout-col workout-col-2">
                  <img
                    src="images/workouts/dumbbell-2.png"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="workout-left">
                    <h4>Basic HIIT</h4>
                  </div>
                  <div className="workout-right workout-dumbbell">
                    <h4>Beginner</h4>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="workout-col workout-col-2">
                  <img
                    src="images/workouts/dumbbell-3.png"
                    className="img-fluid"
                    alt=""
                  />
                  <div className="workout-left">
                    <h4>Upper Body</h4>
                  </div>
                  <div className="workout-right workout-dumbbell">
                    <h4>All Levels</h4>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
};

export default WorkoutsProgams;
