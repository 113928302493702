// User Sign up
export const SIGN_UP_DETAILS = "SIGN_UP_DETAILS";
export const SIGN_UP_WITH_EMAIL = "SIGN_UP_WITH_EMAIL";
// USER LOGIn
export const LOGIN_DETAILS = "LOGIN_DETAILS";
// GET HOME PAGE
export const GET_HOME_PAGE = "GET_HOME_PAGE";
// GET PACKAGE
export const GET_PACKAGE = "GET_PACKAGE";
// GET PACKAGE PROGRAM
export const GET_PROGRAM = "GET_PROGRAM";
// GET DIET PLANS
export const GET_NUTRITION_DIET_PLAN = "GET_NUTRITION_DIET_PLAN";
// GET LIVE SESSION
export const GET_LIVE_SESSION = "GET_LIVE_SESSION";
// GET YOUR PLAN
export const GET_YOUR_PLAN = "GET_YOUR_PLAN";
// JOIN PROGRAM
export const JOIN_PROGRAM = "JOIN_PROGRAM";
// GET USER DETAILS
export const GET_USER_DETAILS = "GET_USER_DETAILS";
//GET SUBSCRIPTION LIST
export const GET_SUBSCRIPTION_HISTORY ="GET_SUBSCRIPTION_HISTORY";
// SET NEW PASSWORD
export const SET_NEW_PASSWARD = "SET_NEW_PASSWARD";
// SEND ENQUIRY
export const CONTACT_INFO = "CONTACT_INFO";

// TERMS & CONDITION
export const GET_TERMS_CONDITION = "GET_TERMS_CONDITION";
export const GET_REFUNDT_POLICY = "GET_REFUNDT_POLICY";
export const GET_PRIVACY_POLICY = "GET_PRIVACY_POLICY";

// SUBSSCRIBE WITH US
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION";

//get Add 
export const GET_ADS = "GET_ADS"

export const  GET_BLOG = "GET_BLOG"

//  GET IMAFGES 
export const GET_WEB_IMAGE = "GET_WEB_IMAGE"

// GET CATEGORY
export const GET_CATEGORY = "GET_CATEGORY";

// GET MEAL 
export const GET_NUTRITION_MEAL = "GET_NUTRITION_MEAL"