import React, { useEffect } from "react";
import { getLiveSession  } from "../redux/MainAction";
import { useDispatch, useSelector } from "react-redux";

const JoinLiveSession = () => {
  var log = localStorage.getItem("isloggedIn")
  const dispatch = useDispatch()
  const live = useSelector(state=>state?.HitFitSite?.liveSession)

  useEffect(()=> {
    getList()
  },[]) 
  const getList = () => {
    if(log){
      var data ={
        // includePackages:true,
      }
      dispatch(getLiveSession(data))
    }
  }
  console.log("LIVE", live);
  return (
    <>
      <section className="innerpage-section live-session">
        <div className="padding-left-right">
          <div className="container">
            <h2>Live Session</h2>
            <h3>Today's Live Session</h3>
            <div className="row g-2">
              <div className="col-lg-7 col-md-12 col-sm-12 col-video-bid">
                <video autoplay="true" muted="" loop="" playsinline="">
                  <source src="/images/live-session.mp4" type="video/mp4" />
                </video>
              </div>
              <div className="col-lg-5 col-md-12 col-sm-12 live-auction-column">
                <div id="frame">
                  <div className="content-chat">
                    <div className="messages">
                      <ul>
                        <li className="sent">
                          <p>
                            {" "}
                            <img
                              src="/images/session/user-1.png"
                              className="img-fluid img-50"
                              alt=""
                            />{" "}
                            maxjacobson, love from India 👋{" "}
                          </p>
                        </li>
                        <li className="sent">
                          <p>
                            {" "}
                            <img
                              src="/images/session/user-2.png"
                              className="img-fluid img-50"
                              alt=""
                            />{" "}
                            maxjacobson, love from India 👋{" "}
                          </p>
                        </li>
                        <li className="sent">
                          <p>
                            {" "}
                            <img
                              src="/images/session/user-3.png"
                              className="img-fluid img-50"
                              alt=""
                            />{" "}
                            maxjacobson, love from India 👋{" "}
                          </p>
                        </li>
                        <li className="sent">
                          <p>
                            {" "}
                            <img
                              src="/images/session/user-4.png"
                              className="img-fluid img-50 "
                              alt=""
                            />{" "}
                            maxjacobson, love from India 👋{" "}
                          </p>
                        </li>
                        <li className="sent">
                          <p>
                            {" "}
                            <img
                              src="/images/session/user-1.png"
                              className="img-fluid img-50"
                              alt=""
                            />{" "}
                            maxjacobson, love from India 👋{" "}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="message-input message-input-unsold">
                      <div className="wrap">
                        <input
                          type="text"
                          placeholder="Write your message..."
                        />
                        <button className="submit">
                          <i className="fa fa-paper-plane" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default JoinLiveSession;
