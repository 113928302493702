/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import {getBlog} from '../redux/MainAction'
import { useDispatch, useSelector } from "react-redux";

const Blog = () => {

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + 'Read More...';
  };
  
  const dispatch = useDispatch();
  const Blogs = useSelector((state) => state?.HitFitSite?.blog);
  // const [ popData , setPop] = useState([])
  useEffect(() => {
    getBlogs()
  },[]);

  const getBlogs =()=>{
    var data ={
       "isActive":true
    }
    dispatch(getBlog(data))
  }

  // console.log("BLOG", popData);
  const handlePopData = (nut)=> {
    // setPop(nut)
    console.log("nut", nut);
    window.location.href ='/blog/details/'+ nut?.id
  }

  return (
    <>
    <section class="innerpage-section blogs">
      <div class="padding-left-right">
        <div class="container">
          <h2 class="diet-plan-head">Blogs</h2>
          <div class="tab-content" id="pills-tabContent">
            <div
              // class="tab-pane fade show active"
              id="pills-loose"
              // role="tabpanel"
              aria-labelledby="pills-loose-tab"
            >
              <div class="row g-2" style={{cursor:"pointer"}}>
                { Blogs === "" ?
                <span><h2 className="diet-plan-head">No Blogs Here...</h2></span>
                :

                Blogs?.map((blog)=> (
                  <div class="col-lg-6 col-md-12">
                  <a
                    onClick={()=>handlePopData(blog)}
                  >
                    <div class="row diet-plan-col">
                      <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="diet-plan-listings">
                          <img
                            src={blog?.image?.url}
                            class="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-sm-12">
                        <div class="diet-plan-listings">
                          <h3>{blog?.title}</h3>
                          <p>
                           {/* {blog.description} */}
                           <div
                            dangerouslySetInnerHTML={{ __html: truncateText(blog.description, 100) }}
                          />
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
      </>
  );
};

export default Blog;
