import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onPackageProgram , joinProgram }  from "../redux/MainAction";
import { useParams } from "react-router-dom";

const WorkoutPlan = () => {
    var AuthToken = localStorage.getItem("AuthToken")
    var log = "true"
    const param = useParams()
    const dispatch = useDispatch()
    const ProgramList = useSelector(state=>state?.HitFitSite?.program)

    const [ videoData , setVideoData ] = useState([])
    const [ videoDataList , setVideoDataList ] = useState([])
    const [ url , setUrl ] = useState("")
    var ID = param?.prog_id
    useEffect(()=> {
        getList()
      },[]) 
      
      const getList = () => {
        if(log){
          var data ={
            includePackages:true,
            includeWorkouts:true,
            isActive:true
          }
          dispatch(onPackageProgram(data))
        }
      }
      console.log("Vidlist", videoDataList);
       
      useEffect(()=>{
        if(ProgramList?.length>0 && ID){
          var spr = ProgramList?.filter(data=>data?.id==ID)
          console.log("spr",spr[0]);
          setVideoData(spr[0])
          setVideoDataList(spr[0])
          setUrl(spr[0]?.PreviewVideo?.url)
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[ProgramList , ID])
    
      // console.log("DATA" , ProgramList);
      const handleURL = (prog)=> {
       if(AuthToken){
        setVideoDataList(prog)
        setUrl(prog?.Video?.url)
        // console.log("SET", "CLICKED");
        window.open(prog?.Video?.url , '_blank')
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
       }else{
        window.location.href = ("/login")
       }
      }
      const handleClick = ( prog )=>{
        if(AuthToken){
          setVideoDataList(prog)
          setUrl(prog?.Video?.url)
          console.log("SET", "CLICKED");
          // window.location.href= '#myVideo'
          window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
         }else{
          window.location.href = ("/login")
         }
      }
      const handleJoin = (e) => {
        console.log('e' , e);
        if(AuthToken){

          var data={
            programId: parseInt(ID),
            // platform:"google"
          }
          dispatch(joinProgram(data))
          console.log("PLAN", data);
        }else{
          window.location.href = ("/login")
        }
      } 

      console.log('url',url);
  return (
    <>
      <section className="inner-banner-main">
        <div className="container"></div>
      </section>

      <section className="innerpage-section">
        <div className="padding-left-right">
          <div className="container" >
            <h2 >Workout Programs - Plan</h2>
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-12 col-md-12">
                <div className="hittfitt-video">
                 {url!=='' && <video
                    src={url}
                    className="workout-video"
                    autoPlay ="true"
                    id=""
                    controls
                    // muted=""
                    // loop=""
                    playsInline
                  >
                    <source type="video/mp4" />
                  </video>}
                </div>
                <div>
                  <a href={url} target="_blank">
                  <button className="play_btn"><i class="fa-solid fa-play"></i> Play</button>
                  </a>
                </div>
              </div>
            </div>
            <hr />
            <div className="row d-flex justify-content-center text-center">
              <div className="col-lg-7">
                <h3>{videoDataList ? videoDataList?.title : "Quick HIIT Workouts"}</h3>
                <p>
                 { videoDataList? videoDataList?.description : "This workout was designed to help you to perform HIIT by using simple Basic Moves such as push-ups, Jumps & squatsIt is a 30 days program that will change your life."}
                </p>
                <div className="workout-horizontal-listings d-flex justify-content-center text-center">
                  <ul>
                    <li>
                      <img
                        src="/images/workouts/timer.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      {videoDataList?.videoDuration ? videoDataList?.videoDuration: "Preview"}
                    </li>
                    <li>
                      <img
                        src="/images/workouts/flag.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      {videoData?.Workouts?.length} Days
                    </li>
                    {/* <li>
                      <img
                        src="/images/workouts/cal.svg"
                        className="img-fluid"
                        alt=""
                      />{" "}
                      Comments
                    </li> */}
                  </ul>
                </div>
                <button onClick={()=>handleJoin("clicked")} className="join-btn">Join Program</button>
              </div>
            </div>

            <div className="workout-details-col">
              {/* <h3>
                Download Full Workout{" "}
                <img
                  src="/images/workouts/download.svg"
                  className="img-fluid download-icon"
                  alt=""
                />
              </h3> */}
              <div className="row g-2">
                {videoData?.Workouts?.map((item, idx)=>(

                  <div className="col-lg-6">
                  <div className="workout-col-1" key={idx}  onClick={()=>handleClick(item )} style={{cursor:"pointer"}}>
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-12">
                        <div className="workout-listings">
                        <video
                            src={item?.Video?.url}
                            className="workout-video"
                            // autoPlay ="false"
                            id="myVideo"
                            // controls
                            muted=""
                            loop=""
                            playsInline
                        >
                            <source  type="video/mp4" />
                        </video>
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-12">
                        <div className="workout-listings-content">
                          <h4>{item?.title}</h4>
                          <p>
                            {item?.title}
                            {/* <a href="#video1" data-fancybox=""> */}
                             <button 
                             className=" workout-details-play-icon"
                             onClick={(e)=>{
                              e.preventDefault()
                              handleURL(item)
                             }}
                             >
                               <img
                                src="/images/workouts/play-icon.svg"
                                alt=""
                                className="img-fluid"
                              />
                             </button>
                            {/* </a> */}
                          </p>
                          <ul className="workouts-details-listings-desc">
                            <li>
                              <img
                                src="/images/icons/time-icon.png"
                                className="img-fluid"
                                alt=""
                              />{" "}
                              {item?.videoDuration}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>  
    </>
  )
}

export default WorkoutPlan